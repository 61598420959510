import React from "react";

const sizes = {
  textxs: "tw-text-[12px] tw-font-medium",
  texts: "tw-text-[14px] tw-font-medium",
  textmd: "tw-text-[16px] tw-font-medium",
  textlg: "tw-text-[24px] tw-font-medium md:tw-text-[22px]",
  headingxs: "tw-text-[14px] tw-font-semibold",
};

const Title = ({ children, className = "", size = "textmd", as, ...restProps }) => {
  const Component = as || "h6";

  return (
    <Component className={`tw-text-gray-900 tw-font-playfairdisplay ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Title };
