import React from "react";

const sizes = {
  textxl: "tw-text-[36px] tw-font-normal tw-not-italic md:tw-text-[34px] sm:tw-text-[32px]",
  text2xl: "tw-text-[40px] tw-font-normal tw-not-italic md:tw-text-[38px] sm:tw-text-[36px]",
  text3xl: "tw-text-[70px] tw-font-normal tw-not-italic md:tw-text-[48px]",
};

const Text = ({ children, className = "", as, size = "text3xl", ...restProps }) => {
  const Component = as || "p";

  return (
    <Component className={`tw-text-primary_maroon tw-font-playfairdisplay ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Text };
