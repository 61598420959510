import { Helmet } from "react-helmet";
import { Title, Text } from "../../Component";
import Header from "../../Component/Header";
import React from "react";
import ReactPlayer from "react-player";
import Footer from "../../Component/Footer/Footer";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
import { VIDEO_DATA } from "../../Constants/myDharmaContent";

export default function DetailsPage({ isModal = false }) {    //added the modal prop
  return (
    <>
      <Helmet>
        <title>
          Dharma Values - Embrace Pure Love and Positive Emotion with Karishye
        </title>
        <meta
          name="description"
          content="Karishye upholds the pure values of Sanatana Dharma, focusing on love, positive divine emotion, and transformative puja experiences without marketing gimmicks."
        />
      </Helmet>
      <div className="container tw-w-full">
        <div className="tw-flex  tw-flex-col tw-gap-2.5 tw-bg-[url(/public/images/background_image1.png)] tw-bg-cover tw-bg-no-repeat md:tw-h-auto">
           {!isModal && <Header className="tw-mt-2.5" />}
          <div className="tw-flex tw-flex-col tw-items-center tw-py-10 sm:tw-py-5">
            <div className="tw-container-xs tw-flex tw-flex-col tw-gap-[52px] md:tw-px-5 sm:tw-gap-[26px] sm:tw-px-[0]">
              <div className="tw-flex tw-flex-col tw-items-center tw-gap-[50px]">
                <Text
                  as="p"
                  className="tw-text-[70px] tw-font-normal tw-text-primary_maroon md:tw-text-[48px]"
                >
                  Karishye values and beliefs
                </Text>
                {VIDEO_DATA.map((video, index) => (
                  <React.Fragment key={index}>
                    <div className="tw-flex tw-flex-col tw-items-center tw-self-stretch tw-border-[0.5px] tw-border-solid tw-border-orange-800 tw-bg-white-a700 tw-shadow-md md:tw-gap-28 sm:tw-gap-[75px] sm:tw-p-5 sm:tw-h-[332px]">
                      <ReactPlayer
                        url={video.url}
                        width="100%"
                        height="738"
                        title={`YouTube video player ${index + 1}`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className="tw-h-[738px]"
                        controls
                      />
                    </div>
                    <div className="tw-flex tw-flex-col tw-gap-5 tw-ml-auto">
                      {video.description.map((desc, descIndex) => (
                        <div
                          key={descIndex}
                          className="tw-flex tw-items-start tw-gap-3 md:tw-flex-row"
                        >
                          <img
                            src="images/star_dots.png"
                            alt="Description Icon"
                            className="tw-mt-[3px] tw-h-[14px] tw-w-[14px] tw-object-cover sm:tw-mt-[5px]"
                          />
                          <ul className="tw-list-disc tw-list-inside tw-hidden sm:tw-block">
                            <li className="tw-text-[16px] tw-font-medium tw-tracking-[0.16px] tw-text-gray-900 tw-font-playfairdisplay sm:tw-text-[14px]">
                              {desc}
                            </li>
                          </ul>
                          <Title
                            as="h5"
                            className="tw-self-center tw-text-[16px] tw-font-medium tw-tracking-[0.16px] tw-text-gray-900 sm:tw-hidden tw-flex-start"
                          >
                            {desc}
                          </Title>
                        </div>
                      ))}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
        {!isModal && <Footer />}
      </div>
      {!isModal && <Whatsapp />}
    </>
  );
}
