import { Img, Title, Text, NotifySection} from "../../Component";
import React, { useState, useCallback } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GUIDANCE_CONTENT } from "../../Constants/myDharmaContent";

export default function DharmaSection() {
  const [sliderState, setSliderState] = useState(0);
  const sliderRef = React.useRef(null);


  const settings = {
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "dharma-slider",
    afterChange: (current) => setSliderState(current),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  // Extracted slider navigation handler to avoid duplication
  const handleSliderNavigation = useCallback((index) => {
    const itemsInSlide = sliderRef?.current?.innerSlider?.state?.itemsInSlide || 1;
    sliderRef?.current?.slickGoTo(index * itemsInSlide);
  }, []);

  // Simplified SliderNavigationDots component with proper props
  const SliderNavigationDots = () => {
    return (
      <div className="tw-flex tw-items-center tw-justify-center">
        {[...Array(GUIDANCE_CONTENT.length)].map((_, i) => {
          const itemsInSlide = sliderRef?.current?.innerSlider?.state?.itemsInSlide || 1;
          const isActive = sliderState >= i * itemsInSlide && sliderState < (i + 1) * itemsInSlide;

          return (
            <div key={i}>
              <div
                onClick={() => handleSliderNavigation(i)}
                className={`tw-mr-1.5 tw-inline-block tw-h-[6px] tw-cursor-pointer tw-rounded-[3px] ${
                  isActive
                    ? "tw-w-[38px] tw-bg-primary_maroon"
                    : "tw-w-[20px] tw-border tw-border-solid tw-border-primary_maroon"
                }`}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div className="tw-flex md:tw-flex-row md:tw-items-start">
        <div className="tw-flex tw-flex-col tw-items-end tw-gap-[50px] md:tw-gap-[72px] md:tw-self-stretch md:tw-px-5 sm:tw-gap-12 lg:tw-flex-1 md:tw-mt-[30px]">
          <div className="tw-flex tw-w-[90%] tw-flex-col tw-gap-10 sm:tw-gap-0 md:tw-w-full">
            <div className="tw-flex tw-flex-col tw-items-start tw-justify-center tw-gap-2.5">
              <Text
                as="p"
                className="tw-w-full tw-text-[70px] tw-font-normal tw-leading-[96px] tw-text-primary_maroon md:tw-text-[30px] md:tw-leading-normal md:tw-hidden"
              >
                Dhryatheva Jainairithi Dharmam
              </Text>
              <Text
                as="p"
                className="tw-hidden tw-w-full tw-text-[70px] tw-font-normal tw-leading-[96px] tw-text-primary_maroon md:tw-text-[30px] md:tw-leading-normal md:tw-block sm:tw-text-[30px] sm:tw-leading-[32px] sm:tw-ml-[10px]"
              >
                My Dharma app
              </Text>
              <Title
                size="texts"
                as="h1"
                className="tw-text-[14px] tw-font-medium tw-tracking-[0.14px] tw-text-gray-900 md:tw-hidden"
              >
                What we follow is Dharma. Not what we know or believe in
              </Title>
            </div>
            <div className="tw-flex tw-flex-col tw-items-center md:tw-mt-20px tw-text-center">
              <div className="tw-flex tw-items-center tw-gap-[11px] tw-self-stretch sm:tw-flex-col">
                <div className="tw-w-[12%] sm:tw-w-full sm:tw-hidden">
                  <Img
                    src="images/img_icon_page_1.png"
                    alt="Page Icon"
                    className="tw-h-[72px] tw-w-full tw-object-cover md:tw-h-auto"
                  />
                  <div className="tw-relative tw-mt-[-72px] tw-h-[72px] tw-content-center md:tw-h-auto">
                    <Img
                      src="images/img_icon_page_1.png"
                      alt="Stacked Icon"
                      className="tw-mx-auto tw-h-[72px] tw-w-full tw-flex-1 tw-object-cover"
                    />
                    <Img
                      src="images/om_image.png"
                      alt="Group Image"
                      className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-m-auto tw-h-[46px] tw-object-cover"
                    />
                  </div>
                </div>
                <div className="tw-flex-col">
                  <div className="tw-w-full tw-max-w-[600px] tw-mx-auto md:tw-max-w-[300px] sm:tw-max-w-[100px]">
                    <Slider ref={sliderRef} {...settings}>
                      {GUIDANCE_CONTENT.map((item, index) => (
                        <div key={index} className="tw-px-4 sm:tw-px-[0px]">
                          <Title
                            size="texts"
                            as="h2"
                            className="tw-text-14px tw-font-medium tw-leading-6 tw-tracking-[0.14px] tw-text-gray-900 sm:tw-leading-[1] sm:tw-text-[12px]"
                          >
                            {item.title}
                          </Title>
                        </div>
                      ))}
                    </Slider>
                  </div>
                  <SliderNavigationDots />
                </div>
              </div>
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-items-end tw-self-stretch md:tw-hidden">
            <div
              className="tw-h-[20px] tw-w-[97%] md:tw-w-[100%] sm:tw-h-[30px] sm:tw-hidden"
              style={{
                backgroundImage: 'url("images/banner_top_web.png")',
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <NotifySection
            />
          </div>
        </div>
        <span
          className="tw-h-[830px] tw-w-[50%] tw-object-contain md:tw-w-full md:tw-h-[496px] sm:tw-h-[300px] tw-bg-[url('/public/images/bell.png')] tw-bg-right tw-bg-cover sm:tw-h-[250px]"
          alt="Image Three"
        />
      </div>
      <div className="tw-flex tw-flex-1 tw-items-center md:tw-flex-row md:tw-items-start">
        <div className="tw-flex tw-flex-1 tw-flex-col tw-items-end tw-gap-24 md:tw-gap-[72px] md:tw-self-stretch md:tw-px-5 sm:tw-gap-12">
          <div className="tw-hidden tw-flex tw-flex-col tw-items-end tw-self-stretch md:tw-block md:tw-ml-[50px] sm:tw-ml-[20px]">
            <div
              className="tw-h-[30px] tw-w-[90%] sm:tw-h-[30px]"
              style={{
                backgroundImage: 'url("images/banner_top_web.png")',
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            />
            <NotifySection
            />
          </div>
        </div>
      </div>
    </>
  );
}