import React, { useEffect } from "react";
import "./Thankyou.scss";
import Footer from "../../Component/Footer/Footer";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
import { useNavigate } from "react-router-dom";
import Header from "../../Component/Header";

const Thankyou = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const bookingId = localStorage.getItem("BookingId");
    const timer = setTimeout(() => {
      navigate(`/my-bookings/${bookingId}`);    // Redirect to booking detail page after 5 seconds
    }, 5000);

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, [navigate]);

  const booking_Id = localStorage.getItem("BookingId");

  const handleNavigation = () => {
    navigate(`/my-bookings/${booking_Id}`);   // Redirect to booking detail page on button click
  };

  return (
    <>
      <Header />   {/* added new Header component */}
      <section className="karishye-thank-form">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 inner-wrapper">
              <div className="congratulation-img">
                <img src="../assets/images/congratulation.png" alt="Congratulation" />
              </div>
              <h1>Thank you</h1>
              <p>
                We appreciate you contacting Karishye. Our Support team will get
                back in
                <br />
                touch with you soon! Have a great day!
              </p>

              <div className="continue-btn">
                <div className="bookbut" onClick={handleNavigation}>  {/* changede the button text*/}
                  Go to My Booking    {/* changed the button text*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Whatsapp />
    </>
  );
};

export default Thankyou;
