import { Button, Title, Text, Img } from "../../Component";
import React, { useCallback } from "react";

export default function JoinTheWaitingListSection() {

  const scrollToNotifySection = useCallback(() => {
    console.log("Scroll button clicked");
    try {
      const element = document.getElementById('notify-section');
      if (element) {
        console.log("'notify-section' found, scrolling...");
        // Try scrollIntoView first
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
        
        // Fallback: Scroll to the element manually if scrollIntoView doesn't work
        if (window.scrollY !== element.offsetTop) {
          window.scrollTo({
            top: element.offsetTop,
            behavior: 'smooth',
          });
        }
      } else {
        console.log("'notify-section' not found");
      }
    } catch (error) {
      console.error('Error scrolling to section:', error);
    }
  }, []);
  

  return (
    <>
      {/* Join the waiting list section */}
      <div className="tw-flex tw-justify-center">
        <div className="container-xs tw-flex tw-items-center tw-justify-center tw-gap-[38px] md:tw-flex-col md:tw-px-5">
          <Img src="images/img_image_49.png" alt="Promoimage" className="tw-h-[184px] tw-w-[30%] tw-object-contain md:tw-w-full sm:tw-hidden" />
          <div className="tw-flex tw-flex-1 tw-flex-col tw-items-start tw-gap-7 md:tw-self-stretch md:tw-items-center">
            <div className="tw-flex tw-flex-col tw-items-start tw-justify-center tw-gap-1 tw-self-stretch md:tw-text-center">
              <Text
                size="textxl"
                as="p"
                className="tw-text-[36px] tw-font-normal tw-text-primary_maroon md:tw-text-[34px] sm:tw-text-[30px] sm:tw-text-center"
              >
                Join the My Dharma waiting list
              </Text>
              <Img src="images/img_image_49.png" alt="Promoimage" className="tw-hidden tw-h-[184px] tw-w-[30%] tw-object-contain md:tw-w-full sm:tw-block" />
              <Title size="texts" as="h2" className="tw-text-[14px] tw-font-medium tw-tracking-[0.14px] tw-text-gray-900 sm:tw-text-center">
                We will let you know as soon as it&apos;s available for download and use
              </Title>
            </div>
            <Button
              size="md"
              variant="fill"
              shape="square"
              className="md:tw-w-[100%] tw-px-[34px] tw-font-quicksand tw-font-medium tw-tracking-[0.16px] sm:tw-px-5"
              onClick={scrollToNotifySection}
            >
              Join the waiting list
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}