/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../Page/firebase";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../environments/config";
import { UserService } from "../../Services/UserService";
import { Img, Heading, ModalText } from "../../Component";
import { isValidPhoneNumber } from "libphonenumber-js";
import { DEFAULT_SELECTED_COUNTRY,COUNTRY_CODE } from "../../Constants/myDharmaContent";    {/* Default country code */}
const LoginModal = ({
  isOpen,
  data,
  frombooking,
  loginModal,
  setLoginModal,
  fromDetail,
}) => {
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [hideOtp, setHideOtp] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(COUNTRY_CODE[DEFAULT_SELECTED_COUNTRY]);  // Default country
  const [numberWithCode, setNumberWithCode] = useState("");  // Phone number with country code
  const [number, setNumber] = useState("");
  const [signupFormData, setSignupFormData] = useState({
    name: "",
    surname: "",
    phoneNumber: "",
  });
  const [validationError, setValidationError] = useState("");
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const hideLogin = () => {
    setLoginModal(!loginModal);
    setOtpSent(false);
    setOtpError("");
    setHideOtp(false);
    setPhoneNumberError("");
    setValidationError("");
    setErrorMessage("");
    setSignupFormData({
      name: "",
      surname: "",
      phoneNumber: "",
    });
    setNumber("");
    setPin("");
  };

  useEffect(() => {      //function to close the modal when clicked outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const sendOTP = async () => {
    const updatedFormData = {
      ...signupFormData,
      phoneNumber: `+${selectedCountry.phone}${number}`,
    };
    const updatedNumber = `+${selectedCountry.phone}${number}`;    //update the phone number
    setNumberWithCode(updatedNumber);
    setSignupFormData(updatedFormData);
    setHideOtp(true);
    setPhoneNumberError("");
    try {
      if (!signupFormData.name || !signupFormData.surname) {
        setValidationError("Please enter your name and surname.");
        setHideOtp(false);
        return;
      } else {
        setValidationError("");
      }

      if (!isValidPhoneNumber(updatedNumber, selectedCountry.code)) {
        setPhoneNumberError("Please enter a valid phone number.");
        setHideOtp(false);
        return;
      } else {
        setPhoneNumberError("");
      }

      if (!auth) {
        console.error("Firebase auth not initialized");
        return;
      }
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {});
      signInWithPhoneNumber(auth, updatedNumber, recaptcha).then(
        (confirmationResult) => {
          setConfirmationResult(confirmationResult);
          setOtpSent(true);
          setPhoneNumberError("");
          localStorage.setItem("phoneNumber", updatedNumber);
        }
      );
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };
  const maskPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
  
    // Ensure the phone number starts with '+'
    if (!phoneNumber.startsWith('+')) return phoneNumber;
  
    // Find the position of the first digit after the country code
    const startIndex = phoneNumber.indexOf('+') + 3; // Assuming country codes are 2 digits long
    const countryCode = phoneNumber.slice(0, startIndex); // Extract the country code
    const remainingNumber = phoneNumber.slice(startIndex); // Extract the remaining number
  
    // Ensure the number has at least 4 digits
    if (remainingNumber.length < 4) return phoneNumber;
  
    // Mask all but the last 4 digits
    const visibleDigits = remainingNumber.slice(-4);
    const maskedDigits = remainingNumber.slice(0, -4).replace(/\d/g, 'x');
  
    return `${countryCode}${maskedDigits}${visibleDigits}`;
  };
  

  const user = localStorage.getItem("phoneNumber");
  useEffect(() => {
    if (otpVerified) {
      if (frombooking) {
        if (isUser) {
          handleSubmit(); //If we are login from puja page , while logging in we are also submitting the booking
        } else {
          handleSignupAndBooking(); //function to handle both signup and booking
        }
      } else if (isUser) {
        if (fromDetail) {
          // If user is not registered and trying to login from booking detail, reload the same page
          setLoginModal(false);
          navigate(0); // This will reload the current page
        } else {
          navigate("/");
        }
      } else {
        handleSignupSubmit(); // If user is not registered, submit the form
      }
      setOtpVerified(false);
    } else {
      console.error("Error while verifying OTP");
    }
  }, [otpVerified, frombooking, isUser, fromDetail, navigate, setLoginModal]);

  const verifyOTP = async (pin) => {
    try {
      const result = await confirmationResult.confirm(pin);
      console.log("result", result);
      const users = await UserService.getUserDetails();
      const userDetails = Object.values(users).find(
        (u) => u?.phoneNumber === user.toString()
      );
      setCurrentUser(userDetails);
      if (userDetails) {
        setIsUser(true);
        signupFormData.name = userDetails?.name;
        signupFormData.surname = userDetails?.surname;
        localStorage.setItem("user", userDetails.name);
        localStorage.setItem("userId", userDetails.id);
      } else {
        setIsUser(false);
        if (fromDetail) {
          setErrorMessage(
            "Sorry, you have not registered with Karishye and have not done any bookings."
          );
        }
      }
      setOtpVerified(true);
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setOtpError("Invalid OTP. Please try again.");
    }
  };
  const handleSignupAndBooking = async () => {
    await handleSignupSubmit();
    handleSubmit();
  };
  const handleSignupSubmit = async () => {
    if (signupFormData.name === "" || signupFormData.surname === "")
      setValidationError("Please enter your name and surname");
    console.log("iam inside the login");
    if (loading) return;
    setLoading(true);
    if (currentUser && currentUser.id) {
      signupFormData.id = currentUser.id;
    }
    const payload = { data: signupFormData };
    try {
      const response = await UserService.updateUser(payload);
      if (response.status !== 201) {
        throw new Error("User registration failed");
      }
      const data = response.data;
      if (data.data.userId) {
        localStorage.setItem("userId", data.data.userId);
      }
      localStorage.setItem("user", signupFormData.name);
    } catch (error) {
      console.error("Error in submitting form:", error);
    } finally {
      navigate("/");         // Redirect to home page after successful registration
      setLoading(false);
    }
  };

  const handleSignupChange = (event) => {
    const { name, value } = event.target;
    setSignupFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async () => {
    const user = localStorage.getItem("userId");
    data.data.userId = user;
    try {
      const response = await fetch(
        `${BASE_URL}/booking/puja-booking?userId=${user}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        const responseData = await response.json();
        localStorage.setItem(
          "BookingId",
          responseData?.data?.[0]?.identifiers?.[0].id
        ); // Store booking id in local storage
      } else {
        console.error("Error: Failed to submit booking");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    navigate("/booking-success");
  };
  return (
    <div
      className="modal fade signup-form show sm:tw-justify-center"
      role="dialog"
      style={{ display: loginModal ? "flex" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered tw-w-[509px] sm:tw-w-[300px]">
        <div className="modal-content padding">
          <div className="tw-flex tw-flex-col tw-items-center">
            <div className="container tw-flex tw-justify-center md:tw-px-5 tw-h-[550px]">
              <div className="tw-relative tw-h-[550px] tw-w-[509px] tw-bg-white-a700 tw-p-6 sm:tw-p-5">
                <div className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-my-auto tw-ml-3.5 tw-mr-auto tw-flex tw-h-max tw-flex-1 tw-flex-col tw-items-start tw-justify-center tw-gap-[40px] md:tw-ml-0">
                  <div className="tw-mr-3.5 tw-flex tw-flex-col tw-items-start tw-gap-5 tw-self-stretch md:tw-mr-0">
                    <ModalText
                      size="text3xl"
                      as="p"
                      className="tw-font-playfairdisplay tw-text-[30px] tw-font-normal tw-text-primary_maroon"
                    >
                      {!otpSent ? "Login" : "Verify OTP"}
                    </ModalText>
                    <Img
                      src="/images/seperator.png"
                      alt="Login Image"
                      className="tw-h-[6px] tw-w-[96%] tw-object-cover sm:tw-w-[100%] sm:tw-h-[4px]"
                    />
                  </div>
                  {fromDetail && (
                    <div className="note-message tw-text-[12px]">
                      Note: Please enter the phone number used during booking
                      for doing paymemt.
                    </div>
                  )}
                  <div className="tw-mr-3.5 tw-flex tw-flex-col tw-gap-[18px] tw-self-stretch md:tw-mr-0">
                    {!otpSent ? (
                      <>
                        <div className="tw-flex tw-flex-col tw-items-start tw-justify-center tw-gap-1.5">
                          <Heading
                            size="texts"
                            as="h1"
                            className="tw-text-[14px] tw-font-medium tw-tracking-[0.14px]"
                          >
                            <span className="tw-font-[500] tw-text-[14px] tw-font-quicksand">
                              Name
                            </span>
                            <span className="tw-text-[#FF0000]">*</span>
                          </Heading>
                          <div className="tw-flex tw-flex-col tw-items-start tw-self-stretch tw-border tw-border-solid tw-border-gray-400 tw-bg-white-a700 tw-w-[429px] sm:tw-w-full">
                            <input
                              shape="square"
                              type="text"
                              name="name"
                              value={signupFormData.name}
                              onChange={handleSignupChange}
                              placeholder="First name"
                              autoComplete="off"
                              className="tw-self-stretch tw-px-3 tw-font-medium tw-border-none focus:tw-outline-none focus:tw-ring-0"
                            />
                            <div className="tw-border-solid tw-border-[0.5px] tw-border-gray-300 tw-w-[95%] tw-mx-2"></div>
                            <input
                              shape="square"
                              type="text"
                              name="surname"
                              placeholder="Last name"
                              value={signupFormData.surname}
                              onChange={handleSignupChange}
                              autoComplete="off"
                              className="tw-self-stretch tw-px-3 tw-font-medium tw-border-none focus:tw-outline-none focus:tw-ring-0"
                            />
                          </div>
                          {validationError && (
                            <div className="tw-text-red-500 tw-text-sm">
                              {" "}
                              {/* Display validation error */}
                              {validationError}
                            </div>
                          )}
                        </div>
                        <div className="tw-flex tw-flex-col tw-items-start tw-gap-2">
                          <Heading
                            size="texts"
                            as="h3"
                            className="tw-text-[14px] tw-font-medium tw-tracking-[0.14px] tw-text-text_black"
                          >
                            <span className="tw-font-[500] tw-text-[14px] tw-font-quicksand">
                              Phone number
                            </span>
                            <span className="tw-text-[#FF0000]">*</span>
                          </Heading>
                          <div
                            ref={dropdownRef}
                            className="tw-flex tw-items-center tw-border-b tw-border-gray-300 tw-relative focus:tw-outline-none focus:tw-ring-0"
                          >
                            <div
                              className="tw-cursor-pointer tw-p-[9px] tw-border-gray-500 tw-border-r-[0] sm:tw-w-[80px] tw-flex tw-justify-between tw-items-center"
                              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                              style={{ border: "1px solid gray" }}
                            >
                              +{selectedCountry.phone}         {/* Display selected country code */}
                              <img
                                src="/images/drop_down.png"
                                className="tw-ml-[10px] tw-inline-block tw-w-[12px] tw-h-[12px] sm:tw-w-[10px] sm:tw-ml-[0] sm:tw-h-[10px]"
                                alt="dropdown"
                              />
                              {isDropdownOpen && (
                                <div className="tw-w-[200px] tw-absolute tw-top-full tw-left-0 tw-z-10 tw-bg-[white] tw-shadow-lg tw-max-h-60 tw-overflow-y-auto">
                                  {COUNTRY_CODE.map((country) => (
                                    <div
                                      key={country.value}
                                      className="tw-p-2 tw-cursor-pointer hover:tw-bg-gray-100 tw-border-b-2 tw-border-gray tw-flex tw-justify-between tw-text-[14px]"
                                      onClick={() => {
                                        setSelectedCountry(country);
                                        setIsDropdownOpen(false);
                                      }}
                                    >
                                       <span>+{country.phone}</span>   {/*adjusted the code to display the country code */}
                                       <strong>{country.name}</strong>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                            <input
                              type="tel"
                              value={number}
                              onChange={(e) => setNumber(e.target.value)}
                              className="tw-flex-1 tw-outline-none tw-mr-[10px] tw-w-[360px] sm:tw-w-[185px] focus:tw-outline-none focus:tw-ring-0 tw-appearance-none"
                              placeholder="Enter phone number"
                            />
                          </div>
                          {phoneNumberError && (
                            <div className="tw-text-red-500 tw-text-sm">
                              {phoneNumberError}
                            </div>
                          )}
                        </div>
                        <div
                          style={{ display: !hideOtp ? "none" : "block"}}
                            className="tw-w-full sm:tw-scale-75 sm:tw-transform-origin-left"
                          id="recaptcha"
                        ></div>
                      </>
                    ) : (
                      <>
                        <div className="tw-flex tw-flex-col tw-items-start tw-gap-2">
                          <Heading
                            size="texts"
                            as="h3"
                            className="tw-text-[14px] tw-font-medium tw-tracking-[0.14px] tw-text-text_black"
                          >
                            Enter OTP sent to{" "}
                            {maskPhoneNumber(numberWithCode)}    {/* Mask the phone number */}
                          </Heading>
                          <input
                            type="text"
                            value={pin}
                            onChange={(e) => setPin(e.target.value)}
                            className="tw-w-full tw-border tw-border-gray-300 tw-p-2 focus:tw-outline-none focus:tw-ring-0"
                            placeholder="Enter 6-digit OTP"
                          />
                          {otpError && (
                            <div className="tw-text-red-500 tw-text-sm">
                              {otpError}
                            </div>
                          )}
                        </div>
                        <button
                          shape="square"
                          onClick={() => verifyOTP(pin)}
                          className={`tw-w-[170px] tw-border-primary_maroon tw-border tw-py-[9px] tw-px-[38px] 
    tw-bg-[#5A1740] tw-font-quicksand tw-text-[white] tw-font-medium tw-tracking-[0.16px] 
    hover:tw-bg-[#761E54] active:tw-bg-[#4B1436] sm:tw-px-5`} // Verify OTP button
                        >
                          Verify OTP
                        </button>
                      </>
                    )}
                  </div>
                  <button
                    shape="square"
                    onClick={sendOTP}
                    className={`tw-w-[150px] tw-border-primary_maroon tw-border tw-py-[9px] tw-px-[38px] 
    tw-bg-[#5A1740] tw-font-quicksand tw-text-[white] tw-font-medium tw-tracking-[0.16px] 
    hover:tw-bg-[#761E54] active:tw-bg-[#4B1436] sm:tw-px-5`} // Get OTP button
                    style={{ display: hideOtp ? "none" : "block" }}
                  >
                    Get OTP
                  </button>
                </div>
                {!fromDetail && ( //hide the img if we are login from booking detail
                  <Img
                    src="/images/close.png"
                    onClick={hideLogin}
                    className="tw-absolute tw-right-6 tw-top-[25px] tw-m-auto tw-w-[30px] sm:tw-top-[20px] sm:tw-right-3 tw-cursor-pointer"
                    alt="Close"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LoginModal.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.object.isRequired,
  }),
  frombooking: PropTypes.bool,
  loginModal: PropTypes.bool.isRequired,
  setLoginModal: PropTypes.func.isRequired,
  signupFormData: PropTypes.shape({
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  setSignupFormData: PropTypes.func.isRequired,
};

export default LoginModal;
