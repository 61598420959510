/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
//Implemented this page to show the booking details of a particular puja can user can do the payment through this.
import React, { useState, useEffect } from "react";
import {useParams} from "react-router-dom";
import { BookingService, UserService,PaymentService } from '../../Services/index';
import Footer from "../../Component/Footer/Footer";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
import "./BookingDetail.scss";
import LoginModal from "../../Component/Login/Login";
import Header from "../../Component/Header";

function EachBooking() {
  const [userId, setUserId] = useState(null);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [transaction, setTransaction] = useState([]);
  const [remaining, setRemaining] = useState([]);
  const [payment, setPayment] = useState("full");
  const [inputPayment, setInputPayment] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [user, setUser] = useState({});
  const [loginModal, setLoginModal] = useState(false);
  const [signupFormData, setSignupFormData] = useState({
    name: "",
    surname: "",
    phoneNumber: "",
  });

  const { bookingNumber } = useParams();
  useEffect(() => {
    const localStorageUserId = localStorage.getItem("userId");

    if (!localStorageUserId || !bookingNumber) {
      //If user is not loggedIn or if loggedIn with other user then login screen will be shown
      setLoginModal(true);
      return;
    }

    const verifyUser = async (currentUserId, bookingId) => {
      try {
      // Fetching the booking details to get the user ID associated with the booking
      const bookingDetailsResponse = await BookingService.getParticularBookingDetails(bookingId);
      setBookingDetails(bookingDetailsResponse);

      const bookingUserId = bookingDetailsResponse?.user?.id;

      if (bookingUserId != currentUserId) {
        // If user IDs do not match, show login modal
        setLoginModal(true);
      } else {
        setUserId(currentUserId);
      }
      } catch (error) {
      console.error("Error fetching booking details:", error);
      setLoginModal(true);
      }
    };
    verifyUser(localStorageUserId, bookingNumber);
  }, [bookingNumber]);

  function calculateTotalCost(samagriData) {
    //Calculating the total cost of samagri
    if (!Array.isArray(samagriData)) {
      console.error("Expected an array but got", samagriData);
      return 0;
    }
    return samagriData.reduce((total, item) => {
      if (item.isProvidedBy !== "user") {
        return total + item?.samagri?.customerMrp * item.qty;
      }
      return total;
    }, 0);
  }

  const getTransaction = async (bookingId) => {
    try {
      const transactions = await BookingService.getTransactions(bookingId);
      setTransaction(transactions);

      const totalAmount = bookingDetails?.bookingSellingPrice;
      const totalPaidAmount = transactions.reduce(
        (sum, transaction) => sum + transaction.paidAmount,
        0
      );
      const remainingAmount = Math.max(totalAmount - totalPaidAmount, 0);
      setRemaining(remainingAmount);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  useEffect(() => {
    if (bookingNumber) {
      getTransaction(bookingNumber);
    }
  }, [bookingNumber, bookingDetails]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const users = await UserService.getUserDetails();
        const currentUser = Object.values(users).find((u) => u.id === userId);
        setUser(currentUser);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    if (userId) {
      fetchUser();
    }
  }, [userId]);

  const paymentHandler = async (totalPrice, amountToPay) => {
    try {
      const paymentData = {
        amount: amountToPay * 100,
        currency: "INR",
        user_id: userId,
        program_registration_id: 1,
        billing_name: user.name,
        billing_address: user.addressLine1
      };
  
      const order = await PaymentService.initiatePayment(paymentData);
  
      const options = {
        key: process.env.RAZORPAY_KEY_ID,
        amount: order.amount,
        currency: order.currency,
        name: "karishye",
        description: `Booking for ${bookingDetails?.puja_model?.puja?.name || 'Puja'}`,
        image: process.env.REACT_APP_PAYMENT_IMAGE,
        order_id: order.id,
        handler: async function(response) {
          const transactionData = {
            booking_id: bookingDetails?.id,
            transaction_id: response.razorpay_order_id,
            razorpay_id: response.razorpay_payment_id,
            paidAmount: order.amount / 100,
            totalAmount: totalPrice,
            payment_status: "success",
            payment_type: "website"
          };
          await PaymentService.processTransaction(transactionData);
          getTransaction(bookingNumber);
          alert("Payment successful!");
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
  
      const rzp1 = new window.Razorpay(options);
  
      rzp1.on("payment.failed", function (response) {
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      });
  
      rzp1.on("modal.closed", function () {
        document.body.style.overflow = "auto";  // Enable scroll on modal close
      });
  
      rzp1.open();
      document.body.style.overflow = "hidden"; // Disable scroll when modal is open
    } catch (error) {
      console.error("Payment initiation error:", error);
      document.body.style.overflow = "auto"; // Ensure scrolling is enabled after error
    } finally {
      document.body.style.overflow = "auto"; // Ensure scrolling is enabled after any outcome
    }
  };
  

  const handleProceed = (totalPrice) => {
    //Proceeding to the payment checking the minimum payment
    let amountToPay = 0;     //initializing the amount to pay 
    if(transaction.length > 0){ 
      amountToPay = totalPrice;
    }else{
      amountToPay = inputPayment;
    }
    if (amountToPay > 0) {
      const minPayment = totalPrice * 0.1;
      if (parseFloat(amountToPay) < minPayment) {
        setErrorMessage(
          `Minimum payment of 10% (₹${parseInt(minPayment)}) is required.`
        );
        return;
      } else {
        setErrorMessage("");
        paymentHandler(totalPrice, amountToPay);
      }
    }
  };

  const handleInputChange = (e) => {
    setInputPayment(e.target.value);
  };

  const downloadInvoice = async () => {
    //function for dowloding the invoice pdf
    try {
      const invoiceData = {
        billTo: {
          name: user.name,
          address: user.addressLine1,
        },
        items: [
          {
            description: "Priest Expenses",
            quantity: 1,
            unitPrice: bookingDetails?.pujariCost,
            total: bookingDetails?.pujariCost,
          },
          {
            description: "Puja Material Expenses",
            quantity: 1,
            unitPrice: calculateTotalCost(
              bookingDetails?.bookingSamagriMappings
            ),
            total: calculateTotalCost(bookingDetails?.bookingSamagriMappings),
          },
          {
            description: "Service Charge",
            quantity: 1,
            unitPrice: bookingDetails?.puja_model?.serviceFee,
            total: bookingDetails?.puja_model?.serviceFee,
          },
        ],
      };
      const blob = await PaymentService.generateInvoice(invoiceData);

      // Create download link
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading invoice:", error);
    }
  };
  const handleDownloadEstimate = async () => {   //Function for downloading the estimate pdf
    try {
      const estimateData = {
        items: [
          { name: "Priest Expenses", qty: 1, rate: bookingDetails?.pujariCost },
          {
            name: "Puja Material Expenses",
            qty: 1,
            rate: calculateTotalCost(bookingDetails?.bookingSamagriMappings),
          },
          {
            name: "Service Charge",
            qty: 1,
            rate: bookingDetails?.puja_model?.serviceFee,
          },
        ],
      };
      const blob = await PaymentService.generateEstimate(estimateData);

      const url = window.URL.createObjectURL(new Blob([blob]));    //Creating the download link for pdf
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "estimate.pdf");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading estimate:", error);
    }
  };

  return (
    <div>
      <Header />   {/* added new Header component */}
      <div className="newofferingsec mybooking-sec">
        <div className="container">
          <div className="my-bookings-wrapper">
            <div className="bookings-box">
              <div className="accordion" id="myBookingsAccord"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-booking-container">
        <div className="accordion">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <div className="accordion-button collapsed my-booking-pujaname collapse-btn">
                <div className="header-wrapper">
                  <div className="title-wrapper">
                    <img
                      src="/assets/images/Karishye-logomybooking.png"
                      alt=""
                    />
                    <p className="booking-puja-name">
                      {bookingDetails?.puja_model?.puja?.name}
                    </p>
                  </div>
                  <div className="see-details-btn">
                  <div
                    className="details-btn"
                    onClick={() => downloadInvoice()}    //on clicking the invoice button invoice will be downloaded
                  >
                    Invoice
                  </div>
                  <div
                    className="details-btn"
                    onClick={() => handleDownloadEstimate()}    //on clicking the estimate button estimate will be downloaded
                  >
                    Estimate
                  </div>
                </div>
                </div>
              </div>
            </h2>
            <div
              id="collapse"
              className="accordion-collapse collapse"
              aria-labelledby="mybookingsBtn"
              data-bs-parent="#myBookingsAccord"
              style={{ display: "block" }}
            >
              <div className="accordion-body padding-top-0">
                <div className="mybookings-body-top">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="booking-details-box">
                        <h6>
                          <span>
                            <img src="/assets/images/user.png" alt="" /> {/*added the root relative image path for all the images*/}
                          </span>
                          {bookingDetails?.name ? (
                            bookingDetails.name
                          ) : bookingDetails?.user?.name ? (
                            bookingDetails.user.name
                          ) : (
                            <i>To Be Decided</i>
                          )}
                        </h6>
                        <p>Name</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="booking-details-box">
                        <h6>
                          <span>
                            <img src="/assets/images/user.png" alt="" />
                          </span>
                          {bookingDetails?.pujaris &&
                          bookingDetails?.pujaris.name &&
                          bookingDetails?.bookingStatus !==
                            "pujari_rejected" ? (
                            bookingDetails?.pujaris.name
                          ) : (
                            <i>To Be Decided</i>
                          )}
                        </h6>
                        <p>Pujari Name</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="booking-details-box">
                        <h6>
                          <span>
                            <img src="/assets/images/rupee.png" alt="" />
                          </span>
                          {bookingDetails?.bookingSellingPrice ? (
                            bookingDetails?.bookingSellingPrice
                          ) : (
                            <i>To Be Decided</i>
                          )}
                        </h6>
                        <p>Final Cost</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="booking-details-box">
                        <div className="calender-time-wrap">
                          <h6>
                            <span>
                              <img src="/assets/images/calender.png" alt="" />
                            </span>
                            {bookingDetails?.startDate ? (
                              bookingDetails?.startDate
                            ) : (
                              <i>To Be Decided</i>
                            )}
                          </h6>
                        </div>
                        <p>Start Date</p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="booking-details-box">
                        <div className="calender-time-wrap">
                          <h6>
                            <span>
                              <img src="/assets/images/calender.png" alt="" />
                            </span>
                            {bookingDetails?.endDate ? (
                              bookingDetails?.endDate
                            ) : (
                              <i>To Be Decided</i>
                            )}
                          </h6>
                        </div>
                        <p>End Date</p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="booking-details-box">
                        <h6>
                          <span>
                            <img src="/assets/images/phone-orange.png" alt="" />
                          </span>
                          {bookingDetails?.pujaris?.phoneNumber ? (
                            bookingDetails?.pujaris?.phoneNumber
                          ) : (
                            <i>To Be Decided</i>
                          )}
                        </h6>
                        <p>Pujari&apos;s Number</p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="booking-details-box">
                        <h6>
                          <span>
                            <img src="/assets/images/marker.png" alt="" />
                          </span>
                          {bookingDetails?.address ? (
                            bookingDetails?.address
                          ) : (
                            <i>To Be Decided</i>
                          )}
                        </h6>
                        <p>Location</p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="booking-details-box">
                        <h6>
                          <span>
                            <img src="/assets/images/marker.png" alt="" />
                          </span>
                          {bookingDetails?.eventType ? (
                            bookingDetails?.eventType
                          ) : (
                            <i>To Be Decided</i>
                          )}
                        </h6>
                        <p>Puja Mode</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mybookings-status">
              <h2>Your Order Status</h2>
              <div className="mybookings-status-wrapper">
                <div className="mybookings-satus-box">
                  <div
                    className={
                      bookingDetails?.bookingStatus === "submitted"
                        ? "mybooking-satus-icon-wrap completed"
                        : "mybooking-satus-icon-wrap"
                    }
                  >
                    <img src="/assets/images/mybookings-satus1.svg" alt="" />
                  </div>
                  <p>Booking Submitted</p>
                </div>
                <div className="mybookings-satus-box">
                  <div
                    className={
                      bookingDetails?.bookingStatus === "plan_under_review"
                        ? "mybooking-satus-icon-wrap completed"
                        : "mybooking-satus-icon-wrap"
                    }
                  >
                    <img src="/assets/images/mybookings-satus2.svg" alt="" />
                  </div>
                  <p>Booking Under Review</p>
                </div>
                <div className="mybookings-satus-box">
                  <div
                    className={
                      bookingDetails?.bookingStatus === "plan_approved"
                        ? "mybooking-satus-icon-wrap completed"
                        : "mybooking-satus-icon-wrap"
                    }
                  >
                    <img src="/assets/images/mybookings-satus3.svg" alt="" />
                  </div>
                  <p>Puja Plan Finalised</p>
                </div>
                <div className="mybookings-satus-box">
                  <div
                    className={
                      bookingDetails?.bookingStatus === "puja_ended"
                        ? "mybooking-satus-icon-wrap completed"
                        : "mybooking-satus-icon-wrap"
                    }
                  >
                    <img src="/assets/images/mybookings-satus5.svg" alt="" />
                  </div>
                  <p>Puja Completed</p>
                </div>
                <div className="mybookings-satus-box">
                  <div
                    className={
                      bookingDetails?.bookingStatus === "pujari_amount_credited"
                        ? "mybooking-satus-icon-wrap completed"
                        : "mybooking-satus-icon-wrap"
                    }
                  >
                    <img src="/assets/images/mybookings-satus4.svg" alt="" />
                  </div>
                  <p>Payment Completed</p>
                </div>
              </div>
            </div>

            <div
              className="booking-details-samagri-and-invoice"
              style={{ display: "block" }}
            >
              <div className="right-side-floater" style={{ display: "flex" }}>
                <div className="my-bookings-invoice">
                  <div className="price-box">
                    <div>
                      <p className="samagri-price-heading">
                        Price breakup
                        <img
                          className="gold-star-line"
                          src="/assets/images/gold-star-with-line.png"
                          alt=""
                        />
                        <img
                          className="gold-star-dots"
                          src="/assets/images/gold-star-with-dots.png"
                          alt=""
                        />
                      </p>
                    </div>
                  </div>
                  <div className="price-box">
                    <div>
                      <div>
                        <p>Pujari</p>
                      </div>

                      <p className="pujari-cost">
                        ₹ {bookingDetails?.pujariCost}
                      </p>
                    </div>
                  </div>
                  <div className="price-box">
                    <div>
                      <div>
                        <p>Samagri</p>
                      </div>
                      <p className="samagri-cost">
                        ₹{" "}
                        {calculateTotalCost(
                          bookingDetails?.bookingSamagriMappings
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="price-box service-charges-floater">
                    <div>
                      <div>
                        <p>Karishye charges</p>
                      </div>
                      <p className="service-price">
                        ₹ {bookingDetails?.puja_model?.serviceFee}
                      </p>
                    </div>
                  </div>
                  <div className="price-box service-charges-floater">
                    <div>
                      <div>
                        <p>GST</p>
                      </div>
                      <p className="service-price">
                        ₹{" "}
                        {Math.round(
                          bookingDetails?.puja_model?.serviceFee * 1.18 -
                            bookingDetails?.puja_model?.serviceFee
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="price-box">
                    <div>
                      <div className="total-incl-tax">
                        <p className="total">Total payable</p>
                        <p className="totalSubDetail">(Incl. of all Taxes) </p>
                      </div>
                      <p className="floater-booked-price">
                        ₹ {bookingDetails?.bookingSellingPrice}
                      </p>
                    </div>
                  </div>
                  {transaction.length === 0 ? (
                    <>
                      <div className="invoice-box">
                        <p className="invoice-payment">
                          <input
                            type="radio"
                            className="radio-yes first"
                            name="muhurtam_fixed"
                            defaultChecked
                            onClick={() => {
                              setPayment("full");
                              setInputPayment(0);
                            }}
                          />
                          Full Payment
                        </p>
                        <p className="invoice-payment">
                          <input
                            type="radio"
                            className="radio-yes"
                            name="muhurtam_fixed"
                            onClick={() => setPayment("advance")}
                          />
                          Pay Advance
                        </p>
                        {payment === "advance" && (
                          <>
                            <input
                              type="number"
                              className="form-control int-fill-input"
                              placeholder="enter amount"
                              onChange={(e) => handleInputChange(e)}
                            />
                            <p className="note">Note: A min. payment of 10% </p>
                            {errorMessage && (
                              <p className="error-message">{errorMessage}</p>
                            )}
                          </>
                        )}
                      </div>
                      <div
                        className="details-book-now"
                        style={{ width: "100%" }}
                      >
                        <a
                          role="button"
                          onClick={() =>
                            handleProceed(bookingDetails?.bookingSellingPrice)
                          }
                          className="bookbut"
                        >
                          Proceed
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="remaining">
                        Remaining Amount : {remaining}
                      </div>
                      <div
                        className="details-book-now"
                        style={{ width: "100%" }}
                      >
                        {remaining !== 0 && (
                          <a
                            onClick={() => handleProceed(remaining)}
                            className="bookbut"
                          >
                            Proceed
                          </a>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="samagri-popup-outer">
                <div className="samagri-popup-container">
                  <div className="my-booking-samagri">
                    <div className="samagri-popup-heading-container">
                      <h2>Puja Details</h2>
                    </div>

                    <div className="samagri-list-container">
                      <p className="samagri-heading">
                        Samagri provided by Karishye (Already included in
                        package)
                        <img
                          className="gold-star-line"
                          src="/assets/images/gold-star-with-line.png"
                          alt=""
                        />
                        <img
                          className="gold-star-dots"
                          src="/assets/images/gold-star-with-dots.png"
                          alt=""
                        />
                      </p>
                      <ul className="samagri-list">
                        {bookingDetails?.bookingSamagriMappings
                          ?.filter(
                            (samagri) => samagri?.isProvidedBy === "karishye"
                          )
                          ?.map((samagri, index) => (
                            <li key={index}>
                              <span className="samagri-item-name">
                                {samagri?.samagri?.name}
                              </span>
                              <span className="samagri-item-quantity">
                                {samagri?.samagri?.standardQty * samagri?.qty}{" "}
                                {samagri?.samagri?.units}
                              </span>
                            </li>
                          ))}
                      </ul>
                    </div>
                    <div className="samagri-also-needed">
                      <p className="samagri-heading">
                        Add-ons
                        <img
                          className="gold-star-line"
                          src="/assets/images/gold-star-with-line.png"
                          alt=""
                        />
                        <img
                          className="gold-star-dots"
                          src="/assets/images/gold-star-with-dots.png"
                          alt=""
                        />
                      </p>
                      <div className="samagri-what-you-need-items">
                        <ul>
                          {bookingDetails?.bookingSamagriMappings
                            ?.filter(
                               (samagri) => samagri?.samagriType === "addon"
                            )
                            ?.map((samagri, index) => (
                              <li key={index}>
                                <img
                                  src="/assets/images/gold-star.png"
                                  alt="Gold star"
                                />
                                <span className="samagri-item-name">
                                  {samagri?.samagri?.name}
                                </span>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="procedure-list-container">
                    <p className="samagri-heading">
                      Procedures
                      <img
                        className="gold-star-line"
                        src="/assets/images/gold-star-with-line.png"
                        alt=""
                      />
                      <img
                        className="gold-star-dots"
                        src="/assets/images/gold-star-with-dots.png"
                        alt=""
                      />
                    </p>
                    <ul className="procedure-list">
                      {bookingDetails?.bookingproceduremappings?.map(
                        (procedure, index) => (
                          <li key={index}>
                            <p className="procedure-title">
                              {procedure?.procedure?.title}
                            </p>
                            <p className="procedure-description">
                              {procedure?.procedure?.description}
                            </p>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                  <div className="samagri-bottom-image-div">
                    <img
                      src="/assets/images/special-notes-icon.png"
                      className="special-notes-img"
                      alt=""
                    />
                  </div>
                  {bookingDetails?.customerNotes && (
                    <div className="booking-special-notes">
                      <h2>Special Notes</h2>
                      <p>{bookingDetails?.customerNotes}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoginModal
          fromDetail={true}
          frombooking={false}
          setSignupFormData={setSignupFormData}
          signupFormData={signupFormData}
          setLoginModal={setLoginModal}
          loginModal={loginModal}
          style={{ display: loginModal ? "flex" : "none" }}
        />
      </div>
      <Footer />
      <Whatsapp />
    </div>
  );
}

export default EachBooking;