import React from "react";

const sizes = {
  section_heading___20_px: "tw-text-[20px] tw-font-semibold",
  body_text___16_px: "tw-text-[16px] tw-font-medium",
  paragraph___16_px: "tw-text-[16px] tw-font-medium",
  textxs: "tw-text-[12px] tw-font-medium",
  texts: "tw-text-[14px] tw-font-medium",
  textmd: "tw-text-[16px] tw-font-medium",
  textlg: "tw-text-[18px] tw-font-medium",
  textxl: "tw-text-[20px] tw-font-medium",
  headingxs: "tw-text-[16px] tw-font-semibold",
};

const Heading = ({ children, className = "", size = "textmd", as, ...restProps }) => {
  const Component = as || "h6";

  return (
    <Component className={`tw-text-text_black tw-font-quicksand ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };
