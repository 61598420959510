import { Helmet } from "react-helmet";
import { Title, Img, Button, Text } from "../../Component";
import Footer from "../../Component/Footer/Footer";
import Header from "../../Component/Header";
import CustomerReviewsSection from "./CustomerReviewsSection";
import IntroductionSection from "./IntroductionSection";
import KarishyePujaExperienceSection from "./KarishyePujaExperienceSection";
import PreservationSection from "./PreservationSection";
import PujaOfferingsSection from "./PujaOfferingsSection";
import SanatanaDharmaSection from "./SanatanaDharmaSection";
import React from "react";
import { useNavigate } from "react-router-dom";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
export default function Home1Page() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>
          Home Rituals - Experience the Essence of Sanatana Dharma with Karishye
        </title>
        <meta
          name="description"
          content="Discover the true spirit of Sanatana Dharma with Karishye. Book pujas, learn about rituals, and connect with experienced pujaris for an authentic spiritual journey."
        />
      </Helmet>
      <div className="tw-w-full">
        <div className="tw-flex tw-h-[910px] tw-flex-col tw-bg-[url(/public/images/background_image1.png)] tw-bg-cover tw-bg-no-repeat md:tw-h-auto">
          <Header className="tw-mt-2.5" />
          <div className="container">
            <div className="tw-flex md:tw-flex-row sm:tw-flex-col sm:tw-items-center">   {/* adjusted the alginment */}
              <div className="tw-flex tw-w-[50%] tw-flex-col tw-align-center tw-gap-24 md:tw-w-full md:tw-gap-[72px] md:tw-px-5 sm:tw-gap-12">
                <div className="tw-flex tw-flex-col tw-items-start tw-gap-[30px] tw-ml-[20px] sm:tw-ml-[0px]">
                  <Text
                    as="p"
                    className="tw-w-full tw-text-[70px] tw-font-normal tw-mt-[117px] tw-leading-[86px] tw-text-primary_maroon md:tw-text-[48px] sm:tw-text-[30px] sm:tw-leading-[30px] sm:tw-mt-[0px] sm:tw-text-center"
                  >
                    Putting the spirit back into the rituals
                  </Text>
                  <Button
                    size="md"
                    variant="fill"
                    shape="square"
                    className="tw-min-w-[242px] tw-px-[34px] tw-font-quicksand tw-font-medium tw-tracking-[0.16px] sm:tw-px-5 sm:tw-min-w-[130px] sm:tw-hidden"
                    onClick={() => navigate("/offerings")}
                  >
                    Book Puja
                  </Button>
                </div>
                <div className="tw-flex tw-flex-col md:tw-hidden">
                  <Img
                    src="images/banner_top_homePage.png"
                    alt="Featured Image"
                    className="tw-h-[10px] tw-w-[96%]"
                  />
                  <div className="tw-black_900_4c_gray_700_4c_border tw-ml-5 tw-flex tw-w-[90%] tw-items-center tw-justify-center tw-gap-4 tw-border-b tw-border-l tw-border-r tw-border-solid tw-border-[#999999] tw-bg-white-a700 tw-p-[30px] tw-shadow-sm md:tw-ml-0 md:tw-w-full sm:tw-flex-col sm:tw-p-5">
                    <Title
                      size="textlg"
                      as="h1"
                      className="tw-text-[24px] tw-font-normal tw-leading-[31px] tw-text-primary_maroon md:tw-text-[22px]"
                    >
                      <>
                        Bring Sanatana Dharma <br />
                        into your day to day life
                      </>
                    </Title>
                    <button
                      shape="square"
                      className={`tw-min-w-[200px] !tw-border tw-border-[#5A1740] tw-px-[20px] tw-py-[13px] 
    tw-font-quicksand tw-font-bold tw-tracking-[0.16px] 
    !tw-text-primary_maroon tw-bg-transparent 
    hover:tw-border-[#761E54] hover:tw-bg-[#FFFAFD] 
    active:tw-border-[#4B1436] active:tw-bg-transparent`} // added hover and active styles
                      onClick={() => navigate("/mydharma")}
                    >
                      Get my Dharma App
                    </button>
                  </div>
                </div>
              </div>
              <Img
                src="images/diya_lamp.png"
                alt="Diya Illustration"
                className="tw-h-[720px] tw-w-[50%] tw-object-contain md:tw-w-full tw-flex-start md:tw-h-[550px] sm:tw-h-[430px]"
              />    {/* adjusted the height and buttons*/}
              <Button
                size="md"
                variant="fill"
                shape="square"
                className="tw-hidden tw-w-[268px] tw-px-[34px] tw-font-quicksand tw-font-medium tw-tracking-[0.16px] sm:tw-px-5 sm:tw-min-w-[130px] sm:tw-block"
                onClick={() => navigate("/offerings")}
              >
                Book Puja
              </Button>
            </div>
            <div className="tw-hidden tw-flex tw-flex-col tw-items-start md:tw-block md:tw-mt-[20px] sm:tw-mt-[30px]">
              <Img
                src="images/banner_top_homePage.png"
                alt="Featured Image"
                className="tw-h-[10px] tw-w-[100%]"
              />
              <div className="tw-black_900_4c_gray_700_4c_border tw-ml-5 tw-flex tw-w-[90%] tw-items-center tw-justify-center tw-gap-4 tw-border-b tw-border-l tw-border-r tw-border-solid tw-border-[#999999] tw-bg-white-a700 tw-p-[30px] tw-shadow-sm md:tw-ml-20px md:tw-w-[95%] sm:tw-flex-col sm:tw-p-5 sm:tw-ml-[8px]">
                <Title
                  size="textlg"
                  as="h1"
                  className="tw-text-[24px] tw-font-normal tw-leading-[31px] tw-text-primary_maroon md:tw-text-[22px]"
                >
                  <>
                    Bring Sanatana Dharma <br />
                    into your day to day life
                  </>
                </Title>
                <button
                  shape="square"
                  className="tw-min-w-[200px] !tw-border tw-px-[19px] tw-font-quicksand tw-font-bold tw-tracking-[0.16px] !tw-text-primary_maroon tw-bg-[#ffffff] tw-px-[20px] tw-py-[13px] hover:tw-border-[#761E54] hover:tw-bg-[#FFFAFD] 
    active:tw-border-[#4B1436] active:tw-bg-transparent"
                  onClick={() => navigate("/mydharma")} // added hover and active styles
                >
                  Get my Dharma App
                </button>
              </div>
            </div>

            {/* karishye puja experience section */}
            <KarishyePujaExperienceSection />

            {/* introduction section */}
            <IntroductionSection />

            {/* puja offerings section */}
            <PujaOfferingsSection />
            <div className="tw-hidden sm:tw-block"><SanatanaDharmaSection/></div>    {/* adjusted the alignment */}

            {/* customer reviews section */}
            <CustomerReviewsSection />

            {/* sanatana dharma section */}
            <div className="sm:tw-hidden"><SanatanaDharmaSection/></div>
            <div className="tw-h-[676px] tw-bg-[url(/public/images/background_image1.png)] tw-bg-cover tw-bg-no-repeat tw-py-20 md:tw-h-auto md:tw-py-5">
              {/* preservation section */}
              <PreservationSection />
            </div>
          </div>
          <div>
            <div>
              <Footer />
              <Whatsapp />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
