import axiosInstance from './AxiosInstance';

export const PujaService = {
  getPujaDetails: async (pujaId) => {
    const response = await axiosInstance.get(`/pujas?id=${pujaId}`);
    return response.data.data[0];
  },
  getSamagriList: async (pujaId) => {
    const response = await axiosInstance.get(`/puja-samagri/${pujaId}`);
    return response.data.data;
  },
  getAllPujas: async () => {
    return axiosInstance.get('/all-pujas'); // Removed redundant await
  },
  getPujaList: async () => {
    const response = await axiosInstance.get('/pujas');
    return response.data.data.map(item => ({
      ...item,
      pujas: item     //changed the data structure
    }));
  },
  getPujaHistory: async () => {
    const response = await axiosInstance.get('/puja-history');
    return response.data.data;
  },
  getRelatedPujas: async (categoryId) => {
    try {
      const response = await axiosInstance.get(`/pujas?categoryId=${categoryId}`);
      return response.data.data.slice(0, 3); 
    } catch (error) {
      console.error('Error fetching related pujas:', error);
      throw error;
    }
  },
};