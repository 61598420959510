import React, { useState } from "react";
import PropTypes from "prop-types";

const shapes = {
  round: "tw-rounded-[18px]",
  circle: "tw-rounded-[50%]",
  square: "tw-rounded-[0px]",
};

const variants = {         // Added new variant fill and outline for hover states
  fill: {
    white_A700: "tw-bg-white-a700",
    primary_maroon: `tw-bg-[#5A1740] tw-text-white-a700 hover:tw-bg-[#761E54] active:tw-bg-[#4B1436]
      disabled:tw-opacity-50 disabled:tw-cursor-not-allowed`,
  },
  outline: {
    primary_maroon: `tw-border-[#5A1740] tw-border tw-text-[#5A1740] hover:tw-border-[#761E54] 
      hover:tw-text-[#761E54] active:tw-border-[#4B1436] active:tw-text-[#4B1436]
      disabled:tw-opacity-50 disabled:tw-cursor-not-allowed`,
  },
};

const sizes = {
  xs: "tw-h-[42px] tw-px-3",
  lg: "tw-h-[52px] tw-px-3",
  md: "tw-h-[50px] tw-px-[34px] tw-text-[16px]",
  sm: "tw-h-[42px] tw-px-5 tw-text-[16px]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "outline",
  size = "sm",
  color = "primary_maroon",
  ...restProps
}) => {
  const [isClicked, setIsClicked] = useState(false);     // Added new state for hover states

  const handleClick = () => {      // Added new function for hover states
    setIsClicked(!isClicked);
  };

  return (
    <button
      className={`${className} tw-flex tw-flex-row tw-items-center tw-justify-center 
        tw-text-center tw-cursor-pointer tw-whitespace-nowrap tw-border-none 
        tw-transition-colors tw-duration-200
        ${shape && shapes[shape]} 
        ${size && sizes[size]} 
        ${variant && variants[variant]?.[color]}
        ${isClicked ? 'tw-bg-[#4B1436] tw-text-white-a700' : ''}
        ${isClicked && variant === 'outline' ? 'tw-border-[#4B1436]' : ''}`}
      onClick={handleClick}
      {...restProps}
    >{/* Added new hover states */}
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["round", "circle", "square"]),
  size: PropTypes.oneOf(["xs", "lg", "md", "sm"]),
  variant: PropTypes.oneOf(["fill", "outline"]),
  color: PropTypes.oneOf(["white_A700", "primary_maroon"]),
};

export { Button };
