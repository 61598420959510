import axiosInstance from './AxiosInstance';

export const PaymentService = {
    initiatePayment: async (paymentData) => {
          const response = await axiosInstance.post('/orders/payment', paymentData);
          return response.data;
      },
  
      processTransaction: async (transactionData) => {
        const response = await axiosInstance.post('/transaction', transactionData);
        return response.data;
      },
    
      generateInvoice: async (invoiceData) => {
        const response = await axiosInstance.post('/users/generate-invoice', invoiceData, {
          responseType: 'blob'
        });
        return response.data;
      },
    
      generateEstimate: async (estimateData) => {
        const response = await axiosInstance.post('/users/generate-estimate', {
          data: estimateData
        }, {
          responseType: 'blob'
        });
        return response.data;
      }
  };