// src/Page/FaqSection.jsx

import React, { useState } from "react";
import { ACCORDION_DATA } from "../../Constants/myDharmaContent";

const FaqSection = () => { // FAQ section to display the frequently asked questions
  const [activeFaq, setActiveFaq] = useState(null);

  const toggleFaq = (id) => {
    setActiveFaq(activeFaq === id ? null : id);
  };

  return (
    <div className="container faq tw-w-[90%] md:tw-w-[90%]" id="faq">
      <h1 className="tw-text-center tw-font-[playfairdisplay] tw-font-[40px] tw-text-[#5A1740] sm:tw-font-[30px]">
        FAQ
      </h1>
      <div
        className="faq-container sm:tw-p-[40px]"
        style={{ fontFamily: "Playfair Display", fontSize: "14px" }}
      >
        <div className="faq-container-inner">
          <ul className="faq-list">
            {ACCORDION_DATA.map((faq) => (
              <li key={faq.id}>
                <button className="accordion" onClick={() => toggleFaq(faq.id)}>
                  {faq.question}
                  <img
                    style={{
                      transform: activeFaq === faq.id ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                    className="faq-arrow-icon"
                    src="../../assets/images/down-icon.png"
                    alt=""
                  />
                </button>
                <div
                  className="panel"
                  style={{
                    display: activeFaq === faq.id ? "block" : "none",
                  }}
                >
                  <p className="panel-text">{faq.answer}</p>
                </div>
              </li>
            ))}
            <div
            type="text"
            className="tw-border-[0.5px] tw-font-playfairdisplay font-medium leading-6 tracking-[0.14px] md:mr-0 sm:px-5 tw-bg-[#D1A2E1] tw-p-[25px] tw-text-[14px]"
            style={{ borderColor: "gold" }}
          >
            <span className="tw-font-bold tw-font-playfairdisplay">Declaimer:</span> Non predesigned puja cards directly go to booking form.
            If user can’t find the puja that he/she is searching for then there
            must be a link directing him to the booking form where he can
            mention the puja name by hand.
          </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;