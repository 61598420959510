import { Title, Text } from "..";
import React from "react";

export default function InformationalTextSection({
  primaryText = "Why?",
  secondaryText = "Videos to understand the purpose behind every puja, so that we connect with the process",
  ...props
}) {
  return (
    <div {...props} className={`${props.className} tw-flex tw-flex-col tw-items-center tw-w-[24%] tw-md:w-full tw-gap-[18px] md:tw-gap-0 md:tw-w-[100%]`}>
      <Text size="textxl" as="p" className="tw-text-[36px] tw-font-normal tw-text-primary_maroon">
        {primaryText}
      </Text>
      <Title
        size="texts"
        as="p"
        className="2xl:tw-self-stretch tw-text-center tw-text-[14px] tw-font-medium tw-leading-6 tw-tracking-[0.14px] tw-text-gray-900 md:tw-w-[50%] md:tw-align-center sm:tw-w-[90%]"
      >
        {secondaryText}
      </Title>
    </div>
  );
}
