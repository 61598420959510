import React, { useState, useRef, useEffect } from "react";
import { UserService } from "../../Services/UserService";
import { Button, Input, Title, Snackbar } from "../../Component";
import { MESSAGE_TYPES,DEFAULT_SELECTED_COUNTRY,COUNTRY_CODE } from "../../Constants/myDharmaContent";    //  message types
export default function NotifySection() {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    key: 0,
  });

  const [selectedCountry, setSelectedCountry] = useState(COUNTRY_CODE[DEFAULT_SELECTED_COUNTRY]); // Default country
  const [number, setNumber] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });

  const dropdownRef = useRef(null);

  const showSnackbar = (message, type = MESSAGE_TYPES.SUCCESS) => {     {/*show snackbar message with varaibles from the constant file*/}
    setSnackbar({
      open: true,
      message,
      type,
      key: Date.now(),
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const updatedFormData = {
      ...formData,
      phoneNumber: `${"+" + selectedCountry.phone}${number}`,     // Concatenating country code with phone number
    };
    setFormData(updatedFormData);

    if (!updatedFormData.name || !updatedFormData.phoneNumber) {
      showSnackbar("Name and phone number are required", MESSAGE_TYPES.WARNING);   // Show warning message
      return;
    }

    try {
      const response = await UserService.registerUser(updatedFormData);
      if (response.status === 201) {
        const text_message = await response.data;
        if (text_message === "Your form has been submitted.")
          showSnackbar(text_message, MESSAGE_TYPES.SUCCESS);
        else showSnackbar(text_message, MESSAGE_TYPES.WARNING);
        setFormData({ name: "", phoneNumber: "" });
        setNumber("");
      } else {
        const error = await response.json();
        showSnackbar(error.message, MESSAGE_TYPES.ERROR);
      }
    } catch (error) {
      showSnackbar("An error occurred. Please try again.", MESSAGE_TYPES.ERROR);    // Show error message
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {    // Cleanup function to remove event listener
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        id="notify-section"
        className="tw-black_900_4c_gray_700_4c_border tw-mr-8 tw-flex tw-w-[88%] tw-justify-center tw-border tw-border-solid tw-bg-white-a700 tw-p-4 md:tw-shadow-sm md:tw-mr-0"
      >
        <div className="tw-mb-[18px] tw-flex tw-w-full tw-flex-col tw-items-start">
          <Title
            size="textlg"
            as="h3"
            className="tw-text-[24px] tw-font-normal tw-text-primary_maroon md:tw-text-[22px] sm:tw-ml-[10px] tw-pl-[10px]"
          >
            Notify me when My Dharma App is LIVE
          </Title>
          <form
            onSubmit={handleSubmit}
            className="tw-flex tw-flex-col tw-items-center tw-gap-[16px] tw-self-stretch sm:tw-flex-col sm:tw-gap-[14px]"
          >
            <div className="tw-flex tw-flex-1 tw-flex-col tw-gap-[16px] tw-items-start sm:tw-self-stretch tw-w-[100%]">
              <div className="tw-w-[100%]">
                <Title
                  size="texts"
                  as="h4"
                  className="tw-font-quicksand tw-pl-[10px] tw-text-[14px] tw-font-medium tw-text-black-900 sm:tw-ml-[10px]"
                >
                  Name
                </Title>
                <Input
                  size="sm"
                  shape="square"
                  name="name"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, name: e.target.value }))
                  }
                  className="tw-self-stretch tw-w-[100%]"
                />
              </div>
              <div className="tw-w-[100%]">
                <Title
                  size="texts"
                  as="h4"
                  className="tw-font-quicksand tw-text-[14px] tw-pl-[10px] tw-font-medium tw-text-black-900 sm:tw-ml-[10px]"
                >
                  Phone number
                </Title>
                <div
                  ref={dropdownRef}
                  className="tw-flex tw-items-center tw-border-b tw-border-gray-300 tw-relative"
                >
                  <div
                    className="tw-w-[90px] tw-cursor-pointer tw-p-2 tw-border-solid tw-border-gray-500 tw-border-[1px] tw-ml-[10px] tw-p-[0.55rem] tw-border-r-0 tw-flex tw-items-center tw-justify-between"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    <div>{"+" + selectedCountry.phone}</div>        {/*adjusted the country code UI interaction*/}
                    <img
                      src="/images/drop_down.png"
                      className="tw-ml-[10px] tw-w-[12px] tw-h-[12px] tw-object-contain"
                      alt="dropdown"
                    />
                  </div>
                  <div className="tw-h-[24px] tw-w-[1px] tw-bg-gray-500"></div>
                  {isDropdownOpen && (
                    <div className="tw-absolute tw-z-10 tw-w-[200px] tw-border tw-bg-white tw-shadow-lg tw-rounded-b tw-top-[100%] tw-ml-[10px]">
                      <ul className="tw-max-h-60 tw-overflow-y-auto tw-bg-[white] tw-text-[13px] tw-font-quicksand tw-font-[600]">
                        {COUNTRY_CODE.map((country) => (
                          <li
                            key={country.value}
                            className="tw-flex tw-justify-between tw-p-2 tw-cursor-pointer hover:tw-bg-gray-100"
                            onClick={() => {
                              setSelectedCountry(country);
                              setIsDropdownOpen(false);
                            }}
                          >
                            <span>+{country.phone}</span>
                            <strong>{country.name}</strong>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <input
                    type="tel"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    className="tw-flex-1 tw-p-2 tw-border tw-border-l-0 tw-mr-[10px]"
                    placeholder="Enter phone number"
                  />
                </div>
              </div>
            </div>
            <Button
              type="submit"
              variant="fill"
              shape="square"
              className="tw-min-w-[130px] tw-ml-[10px] tw-border-black tw-self-start tw-px-[34px] tw-font-quicksand tw-font-medium tw-tracking-[0.16px] sm:tw-self-auto sm:tw-px-5 sm:tw-w-[93%]"
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
      {snackbar.open && (
        <Snackbar
          key={snackbar.key}
          message={snackbar.message}
          type={snackbar.type}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        />
      )}
    </>
  );
}
