import axiosInstance from "./AxiosInstance";

export const UserService = {
  getUserDetails: async () => {
    const response = await axiosInstance.get("/users");
    return response.data.data;
  },
  registerUser: async (data) => {     //function to register user
    return axiosInstance.post("/users/register-user", data);
  },
  updateUser: async (payload) => {
    return axiosInstance.post("/users/create-new", payload); // removed the await
  },
};
