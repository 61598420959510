import { Title, Text } from "../../Component";
import React from "react";

export default function IntroductionSection() {
  return (
    <>
      {/* introduction section */}
      <div>
        <div className="tw-flex tw-h-[1022px] tw-items-center tw-justify-center tw-bg-[url(/public/images/background_image1.png)] tw-bg-cover tw-bg-no-repeat tw-py-20 md:tw-h-auto md:tw-py-5">
          <div className="container-xs tw-flex tw-justify-center md:tw-px-5">
            <div className="tw-flex tw-w-full tw-flex-col tw-gap-[50px]">
              <div className="tw-mx-[202px] tw-flex tw-flex-col tw-items-center tw-gap-2.5 md:tw-mx-0">
                <Text
                  as="p"
                  className="tw-text-[70px] tw-font-normal tw-text-primary_maroon md:tw-text-[48px] md:tw-mt-[60px] sm:tw-text-center sm:tw-text-[34px]"
                >    {/* adjusted the text size */}
                  What is Karishye
                </Text>
                <Title
                  size="texts"
                  as="h2"
                  className="tw-self-stretch tw-text-center tw-text-[14px] tw-font-medium tw-leading-6 tw-tracking-[0.14px] tw-text-gray-900"
                >
                  Karishye is a social impact company that aims to use
                  technology to bring all of us closer to the true form of
                  Sanatana Dharma. 
                  <br className="tw-hidden sm:tw-block"/> We are creating different products that can
                  help serve the vedic ecosystem and make it thriving and
                  self-sufficient
                </Title>
              </div>
              <div className="tw-flex tw-flex-col tw-items-center tw-gap-[72px] tw-border-[0.5px] tw-border-solid tw-border-orange-800 tw-bg-white-a700 tw-p-7 tw-shadow-md md:tw-gap-[54px] sm:tw-gap-9 sm:tw-p-[0.5rem]">
                <video
                  className="tw-h-full tw-w-full tw-object-cover tw-w-[100%]" // Cover the full container size
                  controls
                  src="assets/Essence-of-karishye_with-subs-reduced-size-1.mp4" // Replace with your video path
                  type="video/mp4" // Specify the MIME type
                  alt="Video"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
