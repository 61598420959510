import { Title, Img, Text } from "../../Component";
import React from "react";

export default function DharmaSection1() {
  const redirect = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      {/* dharma section */}
      <div>
        <div className="tw-flex tw-h-[1410px] tw-flex-col tw-items-center tw-justify-center tw-bg-[url(/public/images/background_image1.png)] tw-bg-cover tw-bg-no-repeat tw-py-20 md:tw-h-auto md:tw-py-5">
          <div className="container-xs tw-flex tw-flex-col tw-items-center tw-gap-12 md:tw-px-5 sm:tw-gap-0">
            <Text
              as="p"
              className="tw-text-[70px] tw-font-normal tw-text-primary_maroon md:tw-text-[30px] sm:tw-text-[30px] md:tw-text-center sm:tw-w-[300px]"
            >
              Sanatana Dharma’s View of Time
            </Text>
            <Img
              src="images/sanatana_dharma.png"
              alt="Decorative Image"
              className="tw-h-[758px] tw-w-full tw-object-cover sm:tw-h-[300px]"
            />
            <div className="tw-mx-[148px] tw-flex tw-flex-col tw-items-center tw-gap-5 tw-self-stretch md:tw-mx-0">
              {/* Full Title for larger screens */}
              <Title
                size="texts"
                as="h2"
                className="tw-self-stretch tw-text-center tw-font-quicksand tw-text-[14px] tw-font-medium tw-leading-6 tw-tracking-[0.14px] tw-text-gray-900 sm:tw-hidden"
              >
                <>
                  When a soul takes the form of any life form, depending on what
                  this soul had thrown into the cosmic universe, the counter of
                  that is received back. This is what is referred to as Karma
                  <br />
                  <br />
                  During this present life form some of that karma comes into
                  experience in the form of happiness and sadness, victory and
                  loss etc.. <br />
                  Depending on what actions and what mental decisions one must
                  take in order for those events to happen, the person innately
                  has, or through various friendships, life situations
                  etc..develops certain habits, likes, dislikes, fears,
                  preferences, a form of personality etc..
                  <br />
                  All these are called Karmic tendencies
                  <br />
                  <br />
                  When the rishis saw that every soul is endlessly entrapped in
                  this cycle, they realized that breaking this cycle is the only
                  ultimate aim. Otherwise, once the present life ends, all that
                  is achieved is anyway null and void
                </>
              </Title>
              {/* Shortened Title for small screens */}
              <Title
                size="texts"
                as="h2"
                className="tw-hidden tw-self-stretch tw-text-center tw-font-quicksand tw-text-[14px] tw-font-medium tw-leading-6 tw-tracking-[0.14px] tw-text-gray-900 block sm:tw-block"
              >
                When a soul takes the form of any life form, depending on what
                this soul had thrown into the cosmic universe, the counter of
                that is received back. This is what is referred to as Karma
              </Title>
              <button
                shape="square"
                className="tw-min-w-[132px] tw-bg-white tw-px-[23px] tw-py-[8px] tw-font-quicksand tw-font-medium tw-tracking-[0.16px] !tw-text-primary_maroon sm:tw-px-5 hover:tw-border-[#761E54] hover:tw-bg-[#FFFAFD]"
                style={{ border: "solid",backgroundColor: "white"}}   
                onClick={() => redirect("https://youtu.be/gT9ilZQNbKg?si=xcuAbxeeto_FEqPX")}        
             >        {/* added hover styles */}
                Read more
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
