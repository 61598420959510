import React, { useEffect } from 'react';  //added the detail modal component 
import DetailsPage from '../../Page/Details/index.jsx';

const DetailsModal = ({ isOpen, onClose }) => {
  useEffect(() => {   //dunction to prevent scrolling when modal is open 
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div 
      className="modal fade signup-form show tw-fixed tw-inset-0 tw-z-[1000] tw-overflow-hidden tw-flex tw-items-center tw-justify-center"
      role="dialog"
      style={{ display: isOpen ? "flex" : "none" }}
    >
      {/* Overlay */}
      <div 
        className="tw-fixed tw-inset-0 tw-bg-black tw-opacity-50 tw-pointer-events-auto" 
        onClick={onClose}
      />
      
      {/* Modal Content */}
      <div className="modal-dialog modal-dialog-centered tw-relative tw-w-[90%] tw-max-w-[1440px] tw-h-[90vh] tw-m-auto tw-z-[1001]">
        <div className="modal-content tw-h-full tw-w-full tw-overflow-y-auto tw-bg-white tw-rounded-lg">
          <button
            type="button"
            className="btn-close close-btn-loginregister tw-absolute tw-right-8 tw-top-8 tw-z-[1002]"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={onClose}
          />
          <DetailsPage isModal={true} />
        </div>
      </div>
    </div>
  );
};

export default DetailsModal;