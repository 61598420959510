import { Text } from "../../Component";
import React, { Suspense} from "react";
import PujaDetail from "../../Component/PujaDetail";
import jsonData from "../../images";
export default function CardsSection({pujas}) {

const PujaDetailList = ({ pujasList }) => {     // pujaDetailList component to display the list of pujas
  return (
    <Suspense fallback={<div>Loading feed...</div>}>
      {pujasList.length > 0 ? (
        pujasList.map((puja, index) => (
          <PujaDetail key={`pujaDetail-${index}`} pujas={puja} jsonData={jsonData} className="tw-w-[375px]" />
        ))
      ) : (
        <div>No pujas available </div>
      )}
    </Suspense>
  );
};
const pujaCategoryNames = localStorage.getItem("tab"); 
  return (
    <>
      <div>
        <div className="container tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-[url(/public/images/background_image1.png)] tw-bg-cover tw-bg-no-repeat tw-py-[72px] md:tw-h-auto md:tw-py-5">
          <div className="container tw-mb-1.5 md:tw-px-5">
            <div className="tw-flex tw-flex-col tw-items-center tw-gap-5">
              <Text
                size="text2xl"
                as="p"
                className="tw-text-[40px] tw-font-normal tw-text-primary_maroon md:tw-text-[38px] sm:tw-text-[36px]"
              >
                {pujaCategoryNames}    {/* Display the puja category name */}
              </Text>
              <div className="tw-grid tw-grid-cols-3 tw-justify-center tw-gap-10 tw-self-stretch md:tw-grid-cols-2 sm:tw-grid-cols-1">
                <PujaDetailList pujasList={pujas} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
