export const VIDEO_DATA = [
    {
      url: "https://www.youtube.com/watch?v=tenx413rlY4",
      description: [
        "No selling hope or fear. Sanatana Dharma is about pure love and positive divine emotion.",
        "Puja is a transformative experience. One must participate and dissolve in the process. Unless unavoidable, remote pujas or online pujas are not recommended.",
        "We do not believe in marketing gimmicks like sale, discounts, coupon codes etc. No hidden fees and no discounts.",
        "We want to help pujaris create a financial safety net like term and health insurance, investments etc.",
        "Please spread the word about Karishye with your network, friends and family.",
      ],
    },
    {
      url: "https://www.youtube.com/watch?v=_wp4-ekHVXU",
      description: [
        "No selling hope or fear. Sanatana Dharma is about pure love and positive divine emotion.",
        "Puja is a transformative experience. One must participate and dissolve in the process. Unless unavoidable, remote pujas or online pujas are not recommended.",
        "We do not believe in marketing gimmicks like sale, discounts, coupon codes etc. No hidden fees and no discounts.",
        "We want to help pujaris create a financial safety net like term and health insurance, investments etc.",
        "Please spread the word about Karishye with your network, friends and family.",
      ],
    },
    {
      url: "https://www.youtube.com/watch?v=r_8XFDvSwC0",
      description: [
        "No selling hope or fear. Sanatana Dharma is about pure love and positive divine emotion.",
        "Puja is a transformative experience. One must participate and dissolve in the process. Unless unavoidable, remote pujas or online pujas are not recommended.",
        "We do not believe in marketing gimmicks like sale, discounts, coupon codes etc. No hidden fees and no discounts.",
        "We want to help pujaris create a financial safety net like term and health insurance, investments etc.",
        "Please spread the word about Karishye with your network, friends and family.",
      ],
    },
  ];

  export const DEFAULT_USER_PROFILE = {
    userName: "Sridhar Nookala",
    userDescription: "I recently had the pleasure of working with Karishye, and I couldn't be more impressed with the exceptional service they provided. During my short visit to Hyderabad from the US, I had limited time to shop for Puja essentials for my Home Puja Mandir and the Satyanarayana Puja I was planning back in the States."
  };

  export const IMPORTANT_DATES_GRID= [
    {
      importantDatesTitle: "Track important dates",
      importantDatesDescription:
        "Track all your important family dates, birthdays, ancestral dates. The app will remind you and help you perform the required action",
      imgSrc: "images/dharma_app_feature_Icon1.png",
    },
    {
      importantDatesTitle: "Karishye Pooja Services",
      importantDatesDescription:
        "Learn about the true purpose behind every festival and ritual and perform it as per your situation",
      imgSrc: "images/dharma_app_feature_Icon2.png",
    },
    {
      importantDatesTitle: "Daily Puja Helper",
      importantDatesDescription:
        "Guidance on how to maintain your home mandir and how to perform daily puja, as per your schedule and time availability.",
      imgSrc: "images/dharma_app_feature_Icon3.png",
    },
    {
      importantDatesTitle: "Special session for kids",
      importantDatesDescription:
        "Special classes for kids, young adults and seekers to discuss various aspects of Sanatana Dharma and get inspired",
      imgSrc: "images/dharma_app_feature_Icon4.png",
    },
  ];

  export const PUJA_EXPERIENCE_DATA = [
        {
          primaryText: "Why?",
          secondaryText: "Videos to understand the purpose behind every puja, so that we connect with the process",
        },
        {
          primaryText: "Pujari",
          secondaryText: "Our team of pujaris where we try our best to find those with the right anushtanam",
        },
        {
          primaryText: "Samagri",
          secondaryText: "What we offer to god must be as pure as what we offer to our children. No impurities. No plastic.",
        },
        { primaryText: "Values", secondaryText: "Gurudakshina belongs to the pujari. We dont take a single rupee." },
      ];

      export const CUSTOMER_REVIEWS_LIST= [
        {
          userName: "Sridhar Nookala",
          userDescription:
            "I recently had the pleasure of working with Karishye, and I couldn&#39;t be more impressed with the exceptional service they provided. During my short visit to Hyderabad from the US, I had limited time to shop for Puja essentials for my Home Puja Mandir and the Satyanarayana Puja I was planning back in the States.",
        },
        {
          userName: "Roopashree Dhanasingh",
          userDescription:
            "Thanks a million Karishye for making our Housewarming Ceremony a great success and most cherishable moment of our life... !!! :-) Our interaction with your team, starting from procuring pooja samagri to performing the most contented Housewarming pooja and homams by the qualified.",
        },
        {
          userName: "Manasa Gopidi",
          userDescription:
            "Booked a package for Satyanarayana Swami vratham on Karishye and it was a seamless experience from start to end. They offer top-notch customer service through on-time communication at every stage. All the puja samagri was delivered on time.",
        },
      ];


      export const GUIDANCE_CONTENT= [
        {
          title:
            "Easy to understand guidance on how to perform daily puja, how to maintain your home mandir",
        },
        {
          title:
            "How to maintain your home mandir Tips and best practices to keep your home mandir in perfect condition.",
        },
      ];


    export  const VIDEO_CONTENT = [
        {
          url: "https://youtu.be/gT9ilZQNbKg?si=xcuAbxeeto_FEqPX",
          title: "My Dharma App by Karishye",
          description: "My Dharma App provides accessible, insightful guidance for embracing the dharmic way of living. This introduction video gives you an overview of the app's features, from spiritual teachings to daily practices, helping you connect with the true essence of dharma."
        },
      ];

export const PUJA_CATEGORIES = [
  {
    id: 'all-pujas',
    icon: 'images/all_pujas.svg',
    title: 'All Pujas',
    iconAlt: 'Pujas Icon',
    tabName:"All Pujas",    //added the tab name
  },
  {
    id: 'life-event',
    icon: 'images/life_event.png',
    title: 'Life Event Based',
    iconAlt: 'Pujas Icon',
    tabName:"Life Event Based",
  },
  {
    id: 'remedial',
    icon: 'images/remedial.png',
    title: 'Remedial Based',
    iconAlt: 'Pujas Icon',
    tabName:"Remedial",
  },
  {
    id: 'wellbeing',
    icon: 'images/well_being.svg',
    title: 'Well being',
    iconAlt: 'Wellbeing Icon',
    tabName:"Well Being",
  },
  {
    id: 'seasonal',
    icon: 'images/season_special.png',
    title: 'Season Special',
    iconAlt: 'seasonal Icon',
    tabName:"Season Special",
  },
  {
    id: 'wish',
    icon: 'images/wish_fulfilment.png',
    title: 'Wish fulfilment',
    iconAlt: 'wish Icon',
    tabName:"Wish Fulfilment",
  },
  {
    id: 'battles',
    icon: 'images/win_over_battles.png',
    title: 'Win over battles',
    iconAlt: 'battles Icon',
    tabName:"Win over battles",
  },
  {
    id: 'homam',
    icon: 'images/homam_tab.svg',
    title: 'Homam',
    iconAlt: 'Homam Icon',
    tabName:"Homam",
  },
  {
    id: 'vratam',
    icon: 'images/vratam.svg',
    title: 'Vratam',
    iconAlt: 'Vratam Icon',
    className: 'tw-self-start',
    tabName:"Vratam",
  }
];

export const ACCORDION_DATA= [
  {
    id:1,
    question: "What happens once I submit the booking?",
    answer: `Once you submit the booking, it is assigned to a Karishye pujari who will contact you. He will
             understand the exact requirements and make the detailed puja plan along with all the steps,
             samagri and muhurtam (as required). Once you are satisfied with the plan and make the payment the
             puja is confirmed and the pujari time is blocked.`,
  },
  {
    id:2,
    question: "Do I need to pay in advance or only after the puja is completed?",
    answer: `We ask for a partial advance payment. This is mainly for us to arrange for samagri or to ensure
             that we are blocking the pujari time. Once the puja is completed the remaining balance can be
             paid. You also have an option to make the full payment in advance as well. Rest assured, if you
             cancel in advance we will return all the amount.`,
  },
  {
    id:3,
    question: "What if I want to cancel my booking?",
    answer: `Please contact us on our WhatsApp number +91-8121007481. We will cancel your booking from our
             backend and arrange for the refund. Please note: Cancellations made 3 days or more in advance
             of the puja start date will receive a 100% refund. Cancellations made between 3 days to 1 day before
             puja will receive a 50% refund. Cancellations made within 24 hours will not receive a refund because
             we would have already made the required arrangements and our pujari would have not accepted any other
             pujas during that time.`,
  },
  {
    id:4,
    question: "How to reschedule my puja? My plans have changed due to unforeseen circumstances.",
    answer: `Please contact us on our WhatsApp number +91-8121007481. We will reschedule your booking from our
             backend (generally at no extra cost). Please note, when we reschedule we will have to check with the
             pujari's availability and accordingly provide the best possible date options. Please support and bear with us.`,
  },
  {
    id:5,
    question: "Important: Pujari changes in the last minute",
    answer: `Generally at Karishye, we ensure that the same pujari who has been assigned to you in the plan
             comes for the puja. However, in cases where we cannot avoid, such as the pujari falling sick, we
             would have to reassign the puja to a new pujari. This will be communicated to you by our team as
             soon as we come to know about it. However, rest assured that any pujari we send will perform the
             procedure properly.`,
  },
];

export const COUNTRY_CODE = [         //added the all country codes alphabetically
    { name: "Afghanistan", code: "AF", phone: 93 },
    { name: "Aland Islands", code: "AX", phone: 358 },
    { name: "Albania", code: "AL", phone: 355 },
    { name: "Algeria", code: "DZ", phone: 213 },
    { name: "American Samoa", code: "AS", phone: 1684 },
    { name: "Andorra", code: "AD", phone: 376 },
    { name: "Angola", code: "AO", phone: 244 },
    { name: "Anguilla", code: "AI", phone: 1264 },
    { name: "Antarctica", code: "AQ", phone: 672 },
    { name: "Antigua and Barbuda", code: "AG", phone: 1268 },
    { name: "Argentina", code: "AR", phone: 54 },
    { name: "Armenia", code: "AM", phone: 374 },
    { name: "Aruba", code: "AW", phone: 297 },
    { name: "Australia", code: "AU", phone: 61 },
    { name: "Austria", code: "AT", phone: 43 },
    { name: "Azerbaijan", code: "AZ", phone: 994 },
    { name: "Bahamas", code: "BS", phone: 1242 },
    { name: "Bahrain", code: "BH", phone: 973 },
    { name: "Bangladesh", code: "BD", phone: 880 },
    { name: "Barbados", code: "BB", phone: 1246 },
    { name: "Belarus", code: "BY", phone: 375 },
    { name: "Belgium", code: "BE", phone: 32 },
    { name: "Belize", code: "BZ", phone: 501 },
    { name: "Benin", code: "BJ", phone: 229 },
    { name: "Bermuda", code: "BM", phone: 1441 },
    { name: "Bhutan", code: "BT", phone: 975 },
    { name: "Bolivia", code: "BO", phone: 591 },
    { name: "Botswana", code: "BW", phone: 267 },
    { name: "Bouvet Island", code: "BV", phone: 55 },
    { name: "Brazil", code: "BR", phone: 55 },
    { name: "Bulgaria", code: "BG", phone: 359 },
    { name: "Burkina Faso", code: "BF", phone: 226 },
    { name: "Burundi", code: "BI", phone: 257 },
    { name: "Cambodia", code: "KH", phone: 855 },
    { name: "Cameroon", code: "CM", phone: 237 },
    { name: "Canada", code: "CA", phone: 1 },
    { name: "Cape Verde", code: "CV", phone: 238 },
    { name: "Cayman Islands", code: "KY", phone: 1345 },
    { name: "Chad", code: "TD", phone: 235 },
    { name: "Chile", code: "CL", phone: 56 },
    { name: "China", code: "CN", phone: 86 },
    { name: "Christmas Island", code: "CX", phone: 61 },
    { name: "Colombia", code: "CO", phone: 57 },
    { name: "Comoros", code: "KM", phone: 269 },
    { name: "Congo", code: "CG", phone: 242 },
    { name: "Cook Islands", code: "CK", phone: 682 },
    { name: "Costa Rica", code: "CR", phone: 506 },
    { name: "Cote D'Ivoire", code: "CI", phone: 225 },
    { name: "Croatia", code: "HR", phone: 385 },
    { name: "Cuba", code: "CU", phone: 53 },
    { name: "Curacao", code: "CW", phone: 599 },
    { name: "Cyprus", code: "CY", phone: 357 },
    { name: "Czech Republic", code: "CZ", phone: 420 },
    { name: "Denmark", code: "DK", phone: 45 },
    { name: "Djibouti", code: "DJ", phone: 253 },
    { name: "Dominica", code: "DM", phone: 1767 },
    { name: "Dominican Republic", code: "DO", phone: 1809 },
    { name: "Ecuador", code: "EC", phone: 593 },
    { name: "Egypt", code: "EG", phone: 20 },
    { name: "El Salvador", code: "SV", phone: 503 },
    { name: "Equatorial Guinea", code: "GQ", phone: 240 },
    { name: "Eritrea", code: "ER", phone: 291 },
    { name: "Estonia", code: "EE", phone: 372 },
    { name: "Ethiopia", code: "ET", phone: 251 },
    { name: "Faroe Islands", code: "FO", phone: 298 },
    { name: "Fiji", code: "FJ", phone: 679 },
    { name: "Finland", code: "FI", phone: 358 },
    { name: "France", code: "FR", phone: 33 },
    { name: "French Guiana", code: "GF", phone: 594 },
    { name: "French Polynesia", code: "PF", phone: 689 },
    { name: "Gabon", code: "GA", phone: 241 },
    { name: "Gambia", code: "GM", phone: 220 },
    { name: "Georgia", code: "GE", phone: 995 },
    { name: "Germany", code: "DE", phone: 49 },
    { name: "Ghana", code: "GH", phone: 233 },
    { name: "Gibraltar", code: "GI", phone: 350 },
    { name: "Greece", code: "GR", phone: 30 },
    { name: "Greenland", code: "GL", phone: 299 },
    { name: "Grenada", code: "GD", phone: 1473 },
    { name: "Guadeloupe", code: "GP", phone: 590 },
    { name: "Guam", code: "GU", phone: 1671 },
    { name: "Guatemala", code: "GT", phone: 502 },
    { name: "Guernsey", code: "GG", phone: 44 },
    { name: "Guinea", code: "GN", phone: 224 },
    { name: "Guinea-Bissau", code: "GW", phone: 245 },
    { name: "Guyana", code: "GY", phone: 592 },
    { name: "Haiti", code: "HT", phone: 509 },
    { name: "Honduras", code: "HN", phone: 504 },
    { name: "Hong Kong", code: "HK", phone: 852 },
    { name: "Hungary", code: "HU", phone: 36 },
    { name: "Iceland", code: "IS", phone: 354 },
    { name: "India", code: "IN", phone: 91 },
    { name: "Indonesia", code: "ID", phone: 62 },
    { name: "Iraq", code: "IQ", phone: 964 },
    { name: "Ireland", code: "IE", phone: 353 },
    { name: "Isle of Man", code: "IM", phone: 44 },
    { name: "Israel", code: "IL", phone: 972 },
    { name: "Italy", code: "IT", phone: 39 },
    { name: "Jamaica", code: "JM", phone: 1876 },
    { name: "Japan", code: "JP", phone: 81 },
    { name: "Jersey", code: "JE", phone: 44 },
    { name: "Jordan", code: "JO", phone: 962 },
    { name: "Kazakhstan", code: "KZ", phone: 7 },
    { name: "Kenya", code: "KE", phone: 254 },
    { name: "Kiribati", code: "KI", phone: 686 },
    { name: "Korea, Republic of", code: "KR", phone: 82 },
    { name: "Kosovo", code: "XK", phone: 383 },
    { name: "Kuwait", code: "KW", phone: 965 },
    { name: "Kyrgyzstan", code: "KG", phone: 996 },
    { name: "Latvia", code: "LV", phone: 371 },
    { name: "Lebanon", code: "LB", phone: 961 },
    { name: "Lesotho", code: "LS", phone: 266 },
    { name: "Liberia", code: "LR", phone: 231 },
    { name: "Liechtenstein", code: "LI", phone: 423 },
    { name: "Lithuania", code: "LT", phone: 370 },
    { name: "Luxembourg", code: "LU", phone: 352 },
    { name: "Macao", code: "MO", phone: 853 },
    { name: "Madagascar", code: "MG", phone: 261 },
    { name: "Malawi", code: "MW", phone: 265 },
    { name: "Malaysia", code: "MY", phone: 60 },
    { name: "Maldives", code: "MV", phone: 960 },
    { name: "Mali", code: "ML", phone: 223 },
    { name: "Malta", code: "MT", phone: 356 },
    { name: "Marshall Islands", code: "MH", phone: 692 },
    { name: "Martinique", code: "MQ", phone: 596 },
    { name: "Mauritania", code: "MR", phone: 222 },
    { name: "Mauritius", code: "MU", phone: 230 },
    { name: "Mayotte", code: "YT", phone: 262 },
    { name: "Mexico", code: "MX", phone: 52 },
    { name: "Moldova, Republic of", code: "MD", phone: 373 },
    { name: "Monaco", code: "MC", phone: 377 },
    { name: "Mongolia", code: "MN", phone: 976 },
    { name: "Montenegro", code: "ME", phone: 382 },
    { name: "Montserrat", code: "MS", phone: 1664 },
    { name: "Morocco", code: "MA", phone: 212 },
    { name: "Mozambique", code: "MZ", phone: 258 },
    { name: "Myanmar", code: "MM", phone: 95 },
    { name: "Namibia", code: "NA", phone: 264 },
    { name: "Nauru", code: "NR", phone: 674 },
    { name: "Nepal", code: "NP", phone: 977 },
    { name: "Netherlands", code: "NL", phone: 31 },
    { name: "Netherlands Antilles", code: "AN", phone: 599 },
    { name: "New Caledonia", code: "NC", phone: 687 },
    { name: "New Zealand", code: "NZ", phone: 64 },
    { name: "Nicaragua", code: "NI", phone: 505 },
    { name: "Niger", code: "NE", phone: 227 },
    { name: "Nigeria", code: "NG", phone: 234 },
    { name: "Niue", code: "NU", phone: 683 },
    { name: "Norfolk Island", code: "NF", phone: 672 },
    { name: "Norway", code: "NO", phone: 47 },
    { name: "Oman", code: "OM", phone: 968 },
    { name: "Pakistan", code: "PK", phone: 92 },
    { name: "Palau", code: "PW", phone: 680 },
    { name: "Panama", code: "PA", phone: 507 },
    { name: "Papua New Guinea", code: "PG", phone: 675 },
    { name: "Paraguay", code: "PY", phone: 595 },
    { name: "Peru", code: "PE", phone: 51 },
    { name: "Philippines", code: "PH", phone: 63 },
    { name: "Pitcairn", code: "PN", phone: 64 },
    { name: "Poland", code: "PL", phone: 48 },
    { name: "Portugal", code: "PT", phone: 351 },
    { name: "Puerto Rico", code: "PR", phone: 1787 },
    { name: "Qatar", code: "QA", phone: 974 },
    { name: "Reunion", code: "RE", phone: 262 },
    { name: "Romania", code: "RO", phone: 40 },
    { name: "Russian Federation", code: "RU", phone: 7 },
    { name: "Rwanda", code: "RW", phone: 250 },
    { name: "Saint Barthelemy", code: "BL", phone: 590 },
    { name: "Saint Helena", code: "SH", phone: 290 },
    { name: "Saint Kitts and Nevis", code: "KN", phone: 1869 },
    { name: "Saint Lucia", code: "LC", phone: 1758 },
    { name: "Saint Martin", code: "MF", phone: 590 },
    { name: "Samoa", code: "WS", phone: 684 },
    { name: "San Marino", code: "SM", phone: 378 },
    { name: "Sao Tome and Principe", code: "ST", phone: 239 },
    { name: "Saudi Arabia", code: "SA", phone: 966 },
    { name: "Senegal", code: "SN", phone: 221 },
    { name: "Serbia", code: "RS", phone: 381 },
    { name: "Serbia and Montenegro", code: "CS", phone: 381 },
    { name: "Seychelles", code: "SC", phone: 248 },
    { name: "Sierra Leone", code: "SL", phone: 232 },
    { name: "Singapore", code: "SG", phone: 65 },
    { name: "St Martin", code: "SX", phone: 721 },
    { name: "Slovakia", code: "SK", phone: 421 },
    { name: "Slovenia", code: "SI", phone: 386 },
    { name: "Solomon Islands", code: "SB", phone: 677 },
    { name: "Somalia", code: "SO", phone: 252 },
    { name: "South Africa", code: "ZA", phone: 27 },
    { name: "South Sudan", code: "SS", phone: 211 },
    { name: "Spain", code: "ES", phone: 34 },
    { name: "Sri Lanka", code: "LK", phone: 94 },
    { name: "Sudan", code: "SD", phone: 249 },
    { name: "Suriname", code: "SR", phone: 597 },
    { name: "Svalbard and Jan Mayen", code: "SJ", phone: 47 },
    { name: "Swaziland", code: "SZ", phone: 268 },
    { name: "Sweden", code: "SE", phone: 46 },
    { name: "Switzerland", code: "CH", phone: 41 },
    { name: "Syrian Arab Republic", code: "SY", phone: 963 },
    { name: "Taiwan, Province of China", code: "TW", phone: 886 },
    { name: "Tajikistan", code: "TJ", phone: 992 },
    { name: "Thailand", code: "TH", phone: 66 },
    { name: "Timor-Leste", code: "TL", phone: 670 },
    { name: "Togo", code: "TG", phone: 228 },
    { name: "Tokelau", code: "TK", phone: 690 },
    { name: "Tonga", code: "TO", phone: 676 },
    { name: "Trinidad and Tobago", code: "TT", phone: 1868 },
    { name: "Tunisia", code: "TN", phone: 216 },
    { name: "Turkey", code: "TR", phone: 90 },
    { name: "Turkmenistan", code: "TM", phone: 7370 },
    { name: "Tuvalu", code: "TV", phone: 688 },
    { name: "Uganda", code: "UG", phone: 256 },
    { name: "Ukraine", code: "UA", phone: 380 },
    { name: "United Arab Emirates", code: "AE", phone: 971 },
    { name: "United Kingdom", code: "GB", phone: 44 },
    { name: "United States", code: "US", phone: 1 },
    { name: "Uruguay", code: "UY", phone: 598 },
    { name: "Uzbekistan", code: "UZ", phone: 998 },
    { name: "Vanuatu", code: "VU", phone: 678 },
    { name: "Venezuela", code: "VE", phone: 58 },
    { name: "Viet Nam", code: "VN", phone: 84 },
    { name: "Virgin Islands, British", code: "VG", phone: 1284 },
    { name: "Virgin Islands, U.s.", code: "VI", phone: 1340 },
    { name: "Wallis and Futuna", code: "WF", phone: 681 },
    { name: "Western Sahara", code: "EH", phone: 212 },
    { name: "Yemen", code: "YE", phone: 967 },
    { name: "Zambia", code: "ZM", phone: 260 },
    { name: "Zimbabwe", code: "ZW", phone: 263 }
];

export const DEFAULT_SELECTED_COUNTRY = 91;
export const DEFAULT_SELECTED_COUNTRY_CODE = "+91";

export const MESSAGE_TYPES = {      //added the message types
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info'
};
