import React from "react";
import { Title, Img } from "..";

export default function PujaDetail({
  pujas,
  jsonData,
  defaultImage = "assets/images/default-no-puja-image.png",
  goldLineImage = "assets/images/gold-line.png",
  bookNowButtonText = "Know More",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} tw-flex tw-flex-col tw-items-center tw-w-[32%] md:tw-w-full tw-gap-3 tw-px-7 tw-py-[30px] sm:tw-p-5 tw-border-[0.5px] tw-border-solid tw-bg-white-a700 tw-shadow-xs tw-cursor-pointer`}
      onClick={() => {
        localStorage.setItem("pujaId", pujas.pujas?.id);
        let navigateTo =
          "poojas/telugu/" + pujas?.pujas?.url_name;
        window.open(navigateTo, "_self");
      }}
    >
      {jsonData[pujas?.pujas?.url_name] ? (
        <Img
          src={process.env.REACT_APP_IMAGE_URL + jsonData[pujas?.pujas?.url_name]}
          alt={`${pujas?.pujas?.name}-image`}
          className="tw-h-[166px] tw-object-cover tw-w-[317px] md:tw-w-[243px] sm:tw-w-[280px]"
          style={{ border: "0.5px solid #5a1740"}}
        />
      ) : (
        <Img
          src={defaultImage}
          alt={`${pujas?.pujas?.name}-image`}
          className="tw-h-[166px] tw-object-cover tw-w-[317px] md:tw-w-[243px] sm:tw-w-[280px]"
          style={{ border: "0.5px solid #5a1740"}}
        />
      )}
      <div className="tw-flex tw-flex-col tw-gap-4 tw-self-stretch">
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-1">
          <Title size="textlg" as="p" className="tw-text-[24px] tw-font-medium tw-text-gray-900">
            {pujas?.pujas?.name}
          </Title>
          <Title
            size="texts"
            as="p"
            className="tw-w-full tw-font-quicksand tw-text-[14px] tw-font-medium tw-leading-6 tw-tracking-[0.14px] tw-text-gray-900 tw-overflow-hidden tw-line-clamp-3"
          >
            {pujas?.pujas?.description}
          </Title>
        </div>
        <Img
          src={goldLineImage}
          alt="gold-line"
          className="tw-h-[10px] tw-object-cover"
        />
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-2.5">
          <div className="tw-flex tw-items-center tw-self-stretch">
            <div className="tw-flex tw-flex-1 tw-flex-col tw-items-start">
              <div className="tw-flex tw-items-start tw-gap-2 tw-self-stretch">
                {/* Any additional content can go here */}
              </div>
            </div>
            <button
              shape="square"
              className="tw-min-w-[122px] !tw-border tw-font-quicksand tw-font-medium tw-tracking-[0.16px] sm:tw-px-5 tw-bg-[#ffffff] tw-px-[23px] tw-py-[10px] tw-text-[16px] hover:tw-border-[#761E54] hover:tw-bg-[#FFFAFD]"
            >
              {bookNowButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
