import { Helmet } from "react-helmet";
import Header from "../../Component/Header";
import DharmaAppFeaturesSection from "./DharmaAppFeaturesSection";
import DharmaOfferingsSection from "./DharmaOfferingsSection";
import DharmaSection from "./DharmaSection";
import DharmaSection1 from "./DharmaSection1";
import JoinTheWaitingListSection from "./JoinTheWaitingListSection";
import Footer from "../../Component/Footer/Footer";
import React from "react";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
export default function DharmaPage() {
  return (
    <>
      <Helmet>
        <title>About Dharma - Discover the Essence of Sanatana Practices</title>
        <meta
          name="description"
          content="Explore the My Dharma App and learn the significance of daily puja, Sanatana Dharma's view of time, and how to practice and understand Dharma. Join our waiting list for exclusive access."
        />
      </Helmet>
      <div className="tw-w-full">
        <div className="tw-flex tw-h-[910px] tw-flex-col tw-gap-2.5 tw-bg-[url(/public/images/background_image1.png)] tw-bg-cover tw-bg-no-repeat md:tw-h-auto">
          <Header className="tw-mt-2.5 tw-gap-5" />

          {/* dharma section */}
          <DharmaSection />
        </div>

        {/* dharma offerings section */}
        <DharmaOfferingsSection />

        {/* dharma app features section */}
        <DharmaAppFeaturesSection />

        {/* dharma section */}
        <DharmaSection1 />
        <div className="tw-h-[344px] tw-bg-[url(/public/images/background_image1.png)] tw-bg-cover tw-bg-no-repeat tw-py-20 md:tw-h-auto md:tw-py-5">
          {/* join the waiting list section */}
          <JoinTheWaitingListSection />
        </div>
        <Footer/>
        <Whatsapp />
      </div>
    </>
  );
}
