import { Button, Title, Img, Text } from "../../Component";
import PujaDetail from "../../Component/PujaDetail";
import React, { Suspense, useState, useEffect } from "react";
import { PujaService } from "../../Services/PujaService";
import { useNavigate } from "react-router-dom";
import jsonData from "../../images";

const PujaDetailList = ({ pujas, sliceRange }) => {
  return (
    <Suspense fallback={<div>Loading feed...</div>}>
      {pujas.slice(...sliceRange).map((puja, index) => (
        <PujaDetail key={`pujaDetail-${index}`} pujas={puja} jsonData={jsonData} />
      ))}
    </Suspense>
  );
};

export default function PujaOfferingsSection() {
  const [occEventList, setOccEventList] = useState([]);
  const [lifeEventList, setLifeEventList] = useState([]);
  const [active, setActive] = useState("Life Event Based");
  const [pujas, setPujas] = useState([]);
  const navigate = useNavigate();

  const handleClick = (index) => {
    setActive(index); // Update state on click
  };

  useEffect(() => {
    const fetchPujas = async () => {
      try {
        const response = await PujaService.getAllPujas();
        const pujaListData = response.data.data[1].subCategories;
        const lifeEventData = response.data.data[0].subCategories[0].pujasubcategorymappings;
        setLifeEventList(lifeEventData);
        const selectedOcc = pujaListData.find((i) => i.name === active);
        if (selectedOcc) {
          setOccEventList(selectedOcc.pujasubcategorymappings);
        }
      } catch (err) {
        console.log("Error fetching pujas:", err);
      }
    };
    fetchPujas();
  }, [active]);

  useEffect(() => {
    if (active === "Life Event Based") {
      setPujas(lifeEventList);
    } else {
      setPujas(occEventList);
    }
  }, [active, lifeEventList, occEventList]);

  const getImageSrc = (tab) => {
    switch (tab) {
      case "Life Event Based":
        return "images/life_based_events_tab.svg";
      case "Homam":
        return "images/homam_tab.svg";
      case "Well Being":
        return "images/well_being.svg";
      case "Vratam":
        return "images/vratam.svg";
      default:
        return "";
    }
  };

  return (
    <>
      <div>
        <div className="tw-flex tw-h-[972px] tw-items-center tw-justify-center tw-bg-[url(/public/images/background_image1.png)] tw-bg-cover tw-bg-no-repeat tw-py-20 md:tw-h-auto md:tw-py-5">
          <div className="container-xs tw-flex tw-justify-center md:tw-px-5">
            <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-10">
              <div className="tw-flex tw-flex-col tw-gap-10 tw-self-stretch">
                <div className="tw-flex tw-flex-col tw-items-center">
                  <Text as="p" className="tw-text-[70px] tw-font-normal tw-text-primary_maroon md:tw-text-[48px] sm:tw-text-center sm:tw-text-[34px] sm:tw-mt-[20px]"> {/* adjusted the text size */}
                    Our Puja Offerings
                  </Text>
                  <div className="tw-flex tw-items-start tw-justify-center tw-self-stretch md:tw-flex-col">
                    <div className="tw-flex tw-flex-1 tw-justify-between tw-mt-[20px] md:tw-self-stretch sm:tw-flex-row">
                      {["Life Event Based", "Homam", "Well Being", "Vratam"].map((tab) => (
                        <div
                          key={tab}
                          className="tw-flex tw-flex-col tw-items-center tw-gap-1 tw-align tw-cursor-pointer"
                          style={{ borderBottom: active === tab ? "4px solid #5a1740" : "" }}
                          onClick={() => handleClick(tab)}
                        >
                          <Img
                            src={getImageSrc(tab)}
                            alt={`${tab} Icon`}
                            className="tw-h-[42px] tw-w-[42px] sm:tw-hidden"
                          />
                          <Title size="texts" as="h3" className="tw-mb-3 tw-font-quicksand tw-text-[14px] tw-font-medium tw-text-gray-900">
                            {tab}
                          </Title>
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr className="tw-w-full tw-color-[#262525] tw-mt-[0px]" />
                </div>
                <div className="tw-flex tw-gap-10 md:tw-hidden">
                  <PujaDetailList pujas={pujas} sliceRange={[0, 3]} />
                </div>
                <div className="tw-hidden md:tw-flex md:tw-gap-10 md:tw-flex-row sm:tw-hidden">
                  <PujaDetailList pujas={pujas} sliceRange={[0, 2]} />
                </div>
                <div className="tw-hidden md:tw-hidden sm:tw-flex sm:tw-flex-col sm:tw-gap-10">
                  <PujaDetailList pujas={pujas} sliceRange={[0, 1]} />
                </div>
              </div>
              <div className="tw-flex tw-items-center tw-gap-[30px]">
                <Title size="textlg" as="h6" className="tw-self-end tw-text-[24px] tw-font-medium tw-text-gray-900 md:tw-text-[22px]">
                  Would like to see more pujas?
                </Title>
                <Button
                  variant="fill"
                  shape="square"
                  className="tw-min-w-[122px] tw-px-2 tw-font-quicksand tw-font-medium tw-tracking-[0.16px]"
                  onClick={() => {
                    localStorage.setItem("tab", active);
                    navigate("/offerings");
                  }}
                >
                  View all pujas
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
