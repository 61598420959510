import { Img, Title } from "./..";
import React from "react";

export default function Header({ ...props }) {
  const UserId = localStorage.getItem("userId");   // Get user id from local storage
  return (
    <header
      {...props}
      className={`${props.className} tw-flex tw-justify-center tw-items-center tw-ml-[18px] tw-mr-6 md:tw-mx-0`}
    >
      <Img
        src="/images/logo.png"
        alt="Logo Image"
        className="tw-h-[60px] tw-w-[16%] sm:tw-w-[30%] tw-object-contain tw-cursor-pointer"
        onClick={() => window.location.replace("/")}     // Redirect to home page on logo click
      />
      <div className="tw-ml-5 tw-h-px tw-flex-1 tw-bg-gradient md:tw-m-[1.5rem] tw-mt-[15px]" />
      <div className="tw-ml-[18px] tw-flex tw-self-end md:tw-ml-0 sm:tw-self-auto">
        <ul className="tw-flex tw-flex-wrap tw-gap-5 sm:tw-hidden md:tw-hidden">
          <li>
            <a href="/about">
              <Title as="p" className="tw-text-[16px] tw-font-medium tw-text-gray-900 tw-cursor-pointer">
                About Us
              </Title>
            </a>
          </li>
          <li>
            <a href="/mydharma">
              <Title as="p" className="tw-text-[16px] tw-font-medium tw-text-gray-900 tw-cursor-pointer">
                My Dharma
              </Title>
            </a>
          </li>
          <li>
            <a href="/offerings">
              <Title as="p" className="tw-text-[16px] tw-font-medium tw-text-gray-900 tw-cursor-pointer">
                Book a Puja
              </Title>
            </a>
          </li>
          {UserId ? (
          <li>   {/* Show My Bookings link only if user is logged in */}
            <a href="/mybookings">
              <Title as="p" className="tw-text-[16px] tw-font-medium tw-text-gray-900 tw-mr-[20px] tw-cursor-pointer">
                My Bookings
              </Title>
            </a>
          </li>
          ) : null}
        </ul>
      </div>
      <a href="/menu">
        <Img
          src="/images/menu_image.png"
          alt="Promo Image"
          className="tw-ml-6 tw-h-[44px] tw-w-[44px] tw-object-cover md:tw-ml-0 md:tw-w-full tw-cursor-pointer"
        />
      </a>
    </header>
  );
}
